import apple from '../assets/image/icons/apple.svg'
import google from '../assets/image/icons/google.svg'
import samsung from '../assets/image/icons/samsung.svg'

const AppUrls = [
  {
    name: 'App Store',
    url: 'https://apps.apple.com/app/apple-store/id527299553',
    icon: apple
  },
  {
    name: 'Google Play',
    url: 'https://play.google.com/store/apps/details?id=com.aita&referrer=utm_source=viral',
    icon: google
  },
  {
    name: 'Galaxy Store',
    url: 'https://galaxystore.samsung.com/detail/com.aita?badge=aita&session_id=W_c4825c8155ead74dfb9503e8e866a573',
    icon: samsung
  }
]

export default AppUrls
