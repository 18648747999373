import React from 'react'
import style from './style.module.sass'
import { info } from '../../types'
import { formatText } from '../../utils/formatText'

export interface ModalI {
  modalOpen: boolean,
  setModalOpen: (value: boolean) => void
}

const Modal: React.FC<ModalI> = ({ modalOpen, setModalOpen }) => {
  const closeModal = () => {
    setModalOpen(false)
  }

  const list = Object.entries(info).map(([key, value]) => {
    return (
      <li className={style['list-element']} key={key}>
        <h4 className={style['element-title']}>{formatText(key)}</h4>
        <p className={style['element-text']}>{value}</p>
      </li>
    )
  })

  return (
    <div>
      {modalOpen && (
        <div className={style.modal}>
          <div className={style['modal-content-container']}>
            <div className={style['modal-content']}>
              <h3 className={style.title}>Additional info</h3>
              <ul className={style.list}>
                {list}
              </ul>
            </div>
            <button className={style.close} onClick={closeModal}>Ok</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Modal
