import React from 'react'

const List = () => {
  return (
    <ul className='list'>
      <li className='list__element text'>
        Earn 1% cashback for flights <br />
        and 5% for hotels
      </li>
      <li className='list__element text'>
        No blackout dates
      </li>
      <li className='list__element text'>
        No strings attached
      </li>
    </ul>
  )
}

export default List
