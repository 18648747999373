export const TYPE_COMMUNITY = 'TYPE_COMMUNITY'
export const TYPE_SUBSCRIPTION = 'TYPE_SUBSCRIPTION'
export const TYPE_PRIVACY = 'TYPE_PRIVACY'
export const TYPE_FLIGHT_REVIEW = 'TYPE_FLIGHT_REVIEW'
export default class Config {
  constructor () {
    const {
      REACT_APP_COMMUNITY_BASE_URL,
      REACT_APP_COMMUNITY_BASE_PATH,

      REACT_APP_SUBSCRIPTION_BASE_URL,
      REACT_APP_SUBSCRIPTION_BASE_PATH,

      REACT_APP_PRIVACY_BASE_URL,
      REACT_APP_PRIVACY_BASE_PATH,

      REACT_APP_FLIGHT_REVIEW_BASE_PATH,
      REACT_APP_FLIGHT_REVIEW_BASE_URL
    } = process.env
    // community page
    this._apiBaseCommunity =
      REACT_APP_COMMUNITY_BASE_URL +
      REACT_APP_COMMUNITY_BASE_PATH

    // subscription page
    this._apiBaseSubscription = REACT_APP_SUBSCRIPTION_BASE_URL + REACT_APP_SUBSCRIPTION_BASE_PATH

    // privacy page
    this._apiBasePrivacy = REACT_APP_PRIVACY_BASE_URL + REACT_APP_PRIVACY_BASE_PATH

    this._apiBaseFlightReview = REACT_APP_FLIGHT_REVIEW_BASE_URL +
      REACT_APP_FLIGHT_REVIEW_BASE_PATH
  }

  request = async (type, url, body, method = 'POST', contentType = 'application/json') => {
    let baseUrl
    let key
    if (type === TYPE_COMMUNITY) {
      baseUrl = this._apiBaseCommunity
      key = ''
    } else if (type === TYPE_SUBSCRIPTION) {
      baseUrl = this._apiBaseSubscription
      key = `?key=${process.env.REACT_APP_SUBSCRIPTION_KEY}`
    } else if (type === TYPE_PRIVACY) {
      baseUrl = this._apiBasePrivacy
      key = ''
    } else if (type === TYPE_FLIGHT_REVIEW) {
      baseUrl = this._apiBaseFlightReview
      key = ''
    }

    let headers = {}
    let bodyRequest = null

    if (contentType !== 'multipart/form-data') {
      headers = {
        Accept: 'application/json',
        'Content-Type': contentType
      }
      if (method === 'POST') {
        bodyRequest = JSON.stringify(body)
      }
    } else {
      bodyRequest = body
    }

    // eslint-disable-next-line no-undef
    const res = await fetch(`${baseUrl}${url}${key}`, {
      method: method,
      headers: headers,
      body: bodyRequest
    })

    const response = await res.json()
    if (res.ok) {
      return response
    }
    throw new Error(response.detail)
  }
}
