import React, { useRef, useEffect, useState } from 'react'
import logo from '../../assets/image/logo.svg'
import { Link, useLocation } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Lottie from 'lottie-react'
import leafs from './leafs.json'

import './style.sass'
import { logEvent } from '../../analytics'
import { useInViewport } from '../../hook/useInViewport'

const Header = () => {
  const location = useLocation()
  const [headerDark, setHeaderDark] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const lottieRef = useRef()

  const handleSee = (name) => () => logEvent('aita_ap_store_main_otherButton_see', name)

  const businessRef = useInViewport(handleSee('Business'))
  const aboutRef = useInViewport(handleSee('About'))
  const teamRef = useInViewport(handleSee('Team'))
  const communityRef = useInViewport(handleSee('Community'))
  const sustainabilityRef = useInViewport(handleSee('Sustainability'))
  const contactRef = useInViewport(handleSee('Contact Us'))

  const handleClick = (name) => () => {
    logEvent('aita_ap_store_main_otherButton_tap', name)
  }

  useEffect(() => {
    const handleScroll = () => {
      setHeaderDark(window.scrollY > 36)
    }
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [headerDark])
  return (
    <header className={'header ' + (headerDark && 'header-dark')}>
      <div className='container'>
        <Link
          to='/' className='header__link'
          onClick={() => { setShowMenu(false) }}
        >
          <img src={logo} alt='' className='logo' />
        </Link>

        <nav className='header__navigation'>
          <button
            onClick={() => { setShowMenu(!showMenu) }}
            className={'header__burger ' + (showMenu && 'header__burger-open')}
          />
          <ul className={'navigation ' + (showMenu && 'navigation-open')}>
            <li className='navigation__el navigation__el-business' ref={businessRef}>
              <a
                href='https://business.appintheair.com/?utm_source=aita-landing'
                target='blank'
                className='navigation__el-link'
                onClick={() => {
                  setShowMenu(false)
                  handleClick('Business')
                }}
              >
                For Business
              </a>
            </li>
            <li className='navigation__el navigation__el-about' ref={aboutRef}>
              <Link
                to='/'
                onClick={() => {
                  setShowMenu(false)
                  handleClick('About')
                }}
                className={'navigation__el-link ' + (location.pathname === '/' && 'active')}
              >
                About
              </Link>
            </li>
            <li className='navigation__el' ref={teamRef}>
              <Link
                to='/team'
                onClick={() => {
                  setShowMenu(false)
                  handleClick('Team')
                }}
                className={'navigation__el-link ' + (location.pathname === '/team' && 'active')}
              >
                Team
              </Link>
            </li>
            <li className='navigation__el' ref={communityRef}>
              <Link
                to='/community'
                onClick={() => {
                  setShowMenu(false)
                  handleClick('Community')
                }}
                className={'navigation__el-link ' + (location.pathname === '/community' && 'active')}
              >
                Community
              </Link>
            </li>
            <li
              id='sustainability'
              className='navigation__el'
              ref={sustainabilityRef}
            >
              <Lottie
                lottieRef={lottieRef}
                animationData={leafs}
                autoplay={false}
                loop
                onLoopComplete={() => {
                  lottieRef.current.stop()
                }}
              />
              <Link
                to='/sustainability'
                onClick={() => {
                  setShowMenu(false)
                  handleClick('Sustainability')
                }}
                className={'navigation__el-link navigation__el-link-sustainability ' + (location.pathname === '/sustainability' && 'active active__sustainably')}
                onMouseEnter={() => {
                  lottieRef.current.play()
                }}
              >
                Sustainability
              </Link>
            </li>
            <li className='navigation__el' ref={contactRef}>
              <AnchorLink
                offset='100'
                href='#footer'
                onClick={() => {
                  setShowMenu(false)
                  handleClick('Contact Us')
                }}
                className='navigation__el-link'
              >
                Contact Us
              </AnchorLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
