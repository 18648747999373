import React from 'react'
import subData from '../../data/subscription.json'
import List from './_list'
import useWindowSize from '../../hook/useWindowSize'
import { useSelector } from 'react-redux'

const Info = () => {
  const windowSize = useWindowSize()
  const flights = useSelector(state => state.calculation.flights)
  const stays = useSelector(state => state.calculation.stays)
  return (
    <div className='wrapper-left'>
      <div className='text'>
        Calculate your earnings
      </div>
      <div className='text text__big'>
        ${subData[flights][stays] || '15'}+
      </div>
      {windowSize.desktop &&
        <List />}
    </div>
  )
}

export default Info
