import { useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useSelector } from 'react-redux'
import replaceSpacesWithUnderscores from '../../hook/replaceSpacesWithUnderscores'
import './style.sass'

const PrivacySelectMobile = () => {
  const sections = useSelector(state => state.privacy.sections)
  const [isOpen, setIsOpen] = useState(false)
  const [current, setCurrent] = useState(sections[0].attributes.title)

  const onClickItem = (text) => {
    setCurrent(text)
    setIsOpen(false)
  }

  const items = sections.map(item => {
    const href = `#${replaceSpacesWithUnderscores(item.attributes.title)}`
    return (
      <AnchorLink
        key={item.id}
        offset='350'
        href={href}
        className='privacySelect__item'
        onClick={() => {
          onClickItem(item.attributes.title)
        }}
      >
        {item.attributes.title}
      </AnchorLink>
    )
  }
  )

  return (
    <div className='privacySelect'>
      <div className='privacySelect__header' onClick={() => setIsOpen(prev => !prev)}>
        <div className='privacySelect__title'>{current}</div>
        <svg className={`privacySelect__plus ${isOpen ? 'privacySelect__plus-open' : ''}`} width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z' fill='#F8F8F8' />
        </svg>
      </div>
      <div className={`privacySelect__list ${isOpen ? 'privacySelect__list-open' : ''}`}>
        {items}
      </div>
    </div>
  )
}
export default PrivacySelectMobile
