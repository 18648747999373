import React from 'react'
import './style.sass'
import Story from './_story'
import PageSubHeaderText from '../pageSubHeaderText'

const MiniStory = ({ head, text, miniStory, darkMode }) => {
  return (
    <div className='miniStory'>
      <div className='container'>
        <PageSubHeaderText text={head} />
        <div className='miniStory__text' dangerouslySetInnerHTML={{ __html: text }} />
        <div className='miniStory__list'>
          {miniStory.map((story, index) => {
            return <Story key={'culture' + index} story={story} darkMode={darkMode} />
          })}
        </div>
      </div>
    </div>
  )
}

export default MiniStory
