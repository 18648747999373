import React from 'react'
import './_story.sass'

const Story = ({ story, darkMode }) => {
  return (
    <div className='story'>
      <div className={'story__img ' + (darkMode && 'story__img-dark')}>
        <img src={story.img} alt='' className='' />
        {story.picture_text &&
          <div className='story__pictureText'>
            {story.picture_text}
          </div>}
      </div>
      {story.head &&
        <div className='story__head'>
          {story.head}
        </div>}
      <div className='story__text'>
        {story.text}
      </div>
    </div>
  )
}

export default Story
