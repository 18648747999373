import AdrienDuffournet from '../assets/image/community/bugReports/Adrien Duffournet.jpg'
import GemiSakinu from '../assets/image/community/bugReports/Gemi Sakinu.jpg'
import LoganGreene from '../assets/image/community/bugReports/Logan Greene.jpeg'
import YogeshGarg from '../assets/image/community/bugReports/Yogesh Garg.jpg'
import EdanMiles from '../assets/image/community/bugReports/EdanMiles.jpeg'
import JorgenBoll from '../assets/image/community/translators/JørgenBoll.jpeg'
import AlexWilshaw from '../assets/image/community/translators/AlexWilshaw.jpeg'
import TiancongLyu from '../assets/image/community/translators/TiancongLyu.jpeg'

const translators = [
  {
    photo: JorgenBoll,
    name: 'Jørgen Boll',
    profession: '',
    lang: 'Danish',
    text: 'App in the Air is a great and useful app that I have been using with all my flights for many years now. It has all the information you need for a safe and successful trip.',
    flag: 'https://storage.googleapis.com/aita-public/emojis/dk.png'
  },
  {
    photo: TiancongLyu,
    name: 'Tiancong Lyu',
    profession: '',
    lang: 'Chinese Simplified',
    text: 'I think App In the Air is a brilliant travel assistant. It provides handy live information to me while taking a flight and allows me to review all trips I have flown in the past. I\'m the guy that enjoys going over the flight history, especially in the Covid-19 no-fly times. I appreciate that App in the Air provided a chance to bring those good memories up, and expect to get back to the sky soon.',
    flag: 'https://storage.googleapis.com/aita-public/emojis/cn.png'
  },
  {
    photo: AlexWilshaw,
    name: 'Alex Wilshaw',
    profession: '',
    lang: 'Norwegian',
    text: 'App in the Air is fantastic; my favorite travel app. Having all my flights in one place makes flying a breeze, perfect for business-travellers or students like myself. I enjoy looking at my travel history which brings back old memories.',
    flag: 'https://storage.googleapis.com/aita-public/emojis/no.png'
  }
]

const bugReporters = [
  {
    photo: GemiSakinu,
    name: 'Gemi Sakinu L',
    profession: '',
    from: 'From Taiwan',
    info: 'App in the Air is definitely one of my favorite apps. Love all the features including the flight map. The app also helps me navigate airports, find boarding information, and much more!'
  },
  {
    photo: YogeshGarg,
    name: 'Yogesh Garg',
    profession: '',
    from: '',
    info: 'Since the withdrawal of the Worldmate I was looking desperately for a similar, better, and reliable app and found the "App in the Air" as a perfect match my requirements. Besides providing regular alerts, this app also provides another great feature of sending text alerts to mobile phones. I found this as an amazing benefit for elderly people who want to stay away from the smartphones!'
  },
  {
    photo: LoganGreene,
    name: 'Logan Greene',
    profession: '',
    from: '',
    info: 'App in the Air has been a lifesaver with travel! As someone who travels quite frequently (typically weekly), I can attest to the importance for you to have this app. Wondering what gate to go to? App in the Air has the answer. Want to let your friends and family know when you arrive safely? App in the Air has the answer. Want to even know the tail number of the airplane you’re flying on? App in the Air has the answer. I highly recommend this app, and hope you join this community to better travel worldwide!'
  },
  {
    photo: AdrienDuffournet,
    name: 'Adrien Duffournet',
    profession: '',
    from: '',
    info: 'Great tool to gather all my flights into one place. On top of the design, I adore the "map" feature with my travels by country and some statistics.'
  },
  {
    photo: EdanMiles,
    name: 'Edan Miles',
    profession: '',
    from: '',
    info: 'I find App in the Air drastically simplifies the whole airport experience. A raft of features from airport maps, automatic boarding passes in the iPhone Wallet and live information on wait times in security and customs go a long way to making life easier. Add to that a responsive dev team continually adding new features, and you have a very compelling travelling companion.'
  }
]

const CommunityPeople = {
  translators: translators,
  bugReporters: bugReporters
}

export default CommunityPeople
