import React from 'react'
import './style.sass'
import map from '../../assets/image/sustainability/map.png'
import Button from '../button'

const SustainablyMap = ({ setShowCO2 }) => {
  return (
    <div className='sustainablyMap'>
      <img src={map} alt='mao' className='sustainablyMap__img' />
      <Button
        className='btn__darkGreen'
        onClick={() => setShowCO2(true)}
        text='How many trees do you need to plant to offset your CO2 footprint?'
      />
    </div>
  )
}

export default SustainablyMap
