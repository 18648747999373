import React, { useState } from 'react'
import ForPeopleAndThePlanet from '../component/ForPeopleAndThePlanet'
import DoRightByThePlanet from '../component/DoRightByThePlanet'
import SustainablyMap from '../component/sustainablyMap'
import CarbonNeutralTraveler from '../component/CarbonNeutralTraveler'
import Partners from '../component/Partners'
import ChangeTheWorldTogether from '../component/ChangeTheWorldTogether'
import CO2Calculation from '../component/CO2Calculation'
import { Helmet } from 'react-helmet'

const SustainabilityPage = () => {
  const [showCO2, setShowCO2] = useState(false)
  return (
    <div className='page page-sustainably'>
      <Helmet>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <div className='container'>
        <ForPeopleAndThePlanet setShowCO2={setShowCO2} />
        <DoRightByThePlanet />
      </div>
      <SustainablyMap setShowCO2={setShowCO2} />
      <div className='container'>
        <CarbonNeutralTraveler />
        <Partners />
        <ChangeTheWorldTogether />
        <CO2Calculation showCO2={showCO2} setShowCO2={setShowCO2} />
      </div>
    </div>
  )
}

export default SustainabilityPage
