import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import ItemHeader from './_itemHeader'
import './style.sass'

const ContentOpener = ({ text }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <ReactMarkdown
      className={`contentOpener ${isOpen ? 'contentOpener__open' : ''}`}
      components={{
        h3: ({ node }) => (
          <ItemHeader setIsOpen={setIsOpen} title={node.children[0].value} />
        )
      }}
    >
      {text}
    </ReactMarkdown>
  )
}

export default ContentOpener
