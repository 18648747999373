export default function useCloseWebView (data = '') {
  console.log('useCloseWebView', data)
  const event = 'closeWebView'
  try {
    window.webkit.messageHandlers[event].postMessage(data)
  } catch (error) {
    console.error(error)
  }

  try {
    AndroidInterface[event](data)
  } catch (error) {
    console.error(error)
  }
}
