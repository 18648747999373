import React from 'react'
import './style.sass'
import vacancies from '../../data/vacancies'
import Vacancy from './_vacancy'
import PageSubHeaderText from '../pageSubHeaderText'
import _ from 'lodash'

const Vacancies = () => {
  if (!_.size(vacancies)) {
    return ''
  }
  return (
    <div className='vacancies'>
      <div className='container'>
        <PageSubHeaderText text='Open positions' />
        <div className='vacancies__list'>
          {Object.keys(vacancies).map((type, index) => {
            return (
              <div key={'vacancies' + index} className='vacancy__column'>
                <div className='vacancy__head'>
                  {type}
                </div>
                {vacancies[type].map((vacancy, index) => {
                  return <Vacancy key={'vacancy' + index} vacancy={vacancy} />
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Vacancies
