import React from 'react'
import './style.sass'
import PageHeaderText from '../pageHeaderText'
import ourBlog from '../../assets/image/ourBlog.png'
import { logEvent } from '../../analytics'
import { useInViewport } from '../../hook/useInViewport'

const OurBlog = () => {
  const ref = useInViewport(() => logEvent('aita_cp_store_main_writeUsButton_see', 'blog'))

  return (
    <div className='ourBlog'>
      <div className='container'>
        <div className='ourBlog__desc'>
          <PageHeaderText
            text='Be the hero of your <br/> story on our blog.'
          />
          <div className='ourBlog__link'>
            <a href='mailto:marketing@appintheair.com' target='blank' rel='opener' onClick={() => logEvent('aita_cp_store_main_writeUsButton_tap', 'blog')} ref={ref}>Write to us</a>
          </div>
        </div>
        <img src={ourBlog} alt='' className='ourBlog__img' />
      </div>
    </div>
  )
}

export default OurBlog
