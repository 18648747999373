import React from 'react'
import './style.sass'
import SubscriptionList from '../subscriptionList'
import SubscriptionPays from '../subscriptionPays'

const HowWorks = () => {
  return (
    <div className='howWorks'>
      <div className='container'>
        <div className='head'>
          How it works
        </div>
        <SubscriptionList />
        <SubscriptionPays />
      </div>
    </div>
  )
}

export default HowWorks
