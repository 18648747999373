import { Link } from 'react-router-dom'
import React from 'react'
import './style.sass'
import { logEvent } from '../../analytics'
import { useInViewport } from '../../hook/useInViewport'

const Footer = () => {
  const handleSee = (name) => () => logEvent('aita_ap_store_main_otherButton_see', name)

  const careersRef = useInViewport(handleSee('Careers'))
  const helpRef = useInViewport(handleSee('Help Center'))
  const pressKitRef = useInViewport(handleSee('Press Kit'))
  const fbRef = useInViewport(handleSee('Facebook'))
  const instRef = useInViewport(handleSee('Instagram'))
  const twRef = useInViewport(handleSee('Twitter'))
  const ldRef = useInViewport(handleSee('LinkedIn'))
  const ghRef = useInViewport(handleSee('GitHub'))
  const blRef = useInViewport(handleSee('Blog'))

  const handleClick = (name) => () => logEvent('aita_ap_store_main_otherButton_tap', name)

  return (
    <footer className='footer' id='footer'>
      <div className='container'>
        <div className='footer__row'>
          <div className='footer__column column'>
            <div className='column__head'>
              Resources
            </div>
            <div className='column__content'>
              <a
                href='https://appintheair.applytojob.com/apply'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('Careers')}
                ref={careersRef}
              >
                Careers
              </a>
              <a
                href='https://support.appintheair.com'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('Help Center')}
                ref={helpRef}
              >
                Help Center
              </a>
              <a
                href='https://storage.googleapis.com/aita-public/aita-media-pack.zip'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('Press Kit')}
                ref={pressKitRef}
              >
                Press Kit
              </a>
            </div>
          </div>
          <div className='footer__column column'>
            <div className='column__head'>
              Connect
            </div>
            <div className='column__content'>
              <a
                href='https://facebook.com/appintheair'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('Facebook')}
                ref={fbRef}
              >
                Facebook
              </a>
              <a
                href='https://www.instagram.com/appintheair'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('Instagram')}
                ref={instRef}
              >
                Instagram
              </a>
              <a
                href='https://twitter.com/appintheair'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('Twitter')}
                ref={twRef}
              >
                Twitter
              </a>
              <a
                href='https://www.linkedin.com/company/app-in-the-air-inc.'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('LinkedIn')}
                ref={ldRef}
              >
                LinkedIn
              </a>
              <a
                href='https://github.com/appintheair'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('GitHub')}
                ref={ghRef}
              >
                GitHub
              </a>
              <a
                href='/blog'
                target='blank'
                rel='opener'
                className='footer__link footer__link-big'
                onClick={handleClick('Blog')}
                ref={blRef}
              >
                Blog
              </a>
            </div>
          </div>
        </div>
        <div className='footer__row footer__row-second'>
          <div className='footer__column column'>
            <div className='column__head'>
              Contacts
            </div>
            <div className='column__content'>
              <div className='column__head column__head-gray'>Customer support</div>
              <a
                className='footer__link'
                href='mailto:support@appintheair.com'
                target='blank'
                rel='opener'
              >support@appintheair.com
              </a>
              <div className='column__head column__head-gray'>General inquiries</div>
              <a
                className='footer__link'
                href='mailto:team@appintheair.com'
                target='blank'
                rel='opener'
              >team@appintheair.com
              </a>
              <div className='column__head column__head-gray'>Press and marketing contact</div>
              <a
                className='footer__link'
                href='mailto:pr@appintheair.com'
                target='blank'
                rel='opener'
              >pr@appintheair.com
              </a>
              <div className='column__head column__head-gray'>Security issues</div>
              <a
                className='footer__link'
                href='mailto:vulnerability@appintheair.com'
                target='blank'
                rel='opener'
              >vulnerability@appintheair.com
              </a>
            </div>
          </div>
          <div className='footer__column column'>
            <div className='column__head'>
                            &nbsp;
            </div>
            <div className='column__content'>
              <div className='column__head column__head-gray'>Seller of Travel State Registration
                Numbers
              </div>
              <p className='footer__text'>
                California: 2137674-40<br />
                Florida: ST-41801<br />
                Washington: 604326722<br />
                Iowa: 1535<br />
                Hawaii: TAR-7384
              </p>
              <div className='column__head column__head-gray'>Usage of Google APIs</div>
              <p className='footer__text'>
                App in the Air use of information <br />
                received from Google APIs will adhere to <br />
                <a
                  href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'
                  target='blank'
                  rel='opener'
                  className='footer__link'
                >
                  Google API Services User Data Policy
                </a>, <br />
                including the Limited Use requirements. <br />
                <a
                  href='/privacy-policy-google'
                  target='blank'
                  rel='opener'
                  className='footer__link'
                >
                  Google Limited Use Disclosure
                </a>
              </p>
            </div>
          </div>
          <div className='footer__column column column-address'>
            <div className='column__head'>
                            &nbsp;
            </div>
            <div className='column__content'>
              <div className='column__head column__head-gray'>Address</div>
              <p className='footer__text'>
                App in the Air <br />
                1201 3rd Ave <br />
                Suite 2200 <br />
                Seattle, WA 98101
              </p>
            </div>
          </div>
        </div>
        <div className='footer__row footer__row-copy'>
          <div className='footer__column column'>
            <a
              href='/terms-of-use'
              target='blank'
              rel='opener'
              className='footer__link'
            >
              Terms of Service
            </a>
            <Link
              to='/privacy-policy'
              className='footer__link'
            >
              Privacy Policy
            </Link>
            <a
              href='https://storage.googleapis.com/aita-public/aita-vulnerability-disclosure.pdf'
              target='blank'
              rel='opener'
              className='footer__link'
            >
              Vulnerability Disclosure
            </a>
          </div>
          <div className='footer__column column'>
            ©{new Date().getFullYear()} All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
