import style from './style.module.sass'
import React from 'react'
import heart from '../../assets/image/review/heart.svg'
import { Question } from '../../component/question'
import { ReactSVG } from 'react-svg'
import useCloseWebView from '../../hook/useCloseWebView'

export const FinalScreen = ({ error }: {error: string}) => {
  return (
    <>
      <Question
        type={error?.includes('Review already exists') ? '' : 'success'}
      />
      <div className={style.thanks}>
        <ReactSVG src={heart} />
        <p>{(error?.includes('Review already exists') && 'Survey is already done. Thank you for the feedback!') || 'Thank you for the feedback!'}</p>
      </div>
      <button onClick={() => useCloseWebView(error?.includes('Review already exists') ? '' : 'success')} className={style.button}>
        Back to Home
      </button>
    </>
  )
}
