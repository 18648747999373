import style from './style.module.sass'
import shutdownDekstop from '../../assets/image/shutdown/shutdown-dekstop.png'
import shutdownMobile from '../../assets/image/shutdown/shutdown-mobile.png'
import shutdownTablet from '../../assets/image/shutdown/shutdown-tablet.png'

export const ShutdownImage = () => {
  return (
    <div class={style['image-container']}>
      <picture>
        <source media='screen and (max-width: 640px)' srcset={shutdownMobile} />
        <source media='screen and (min-width: 641px) and (max-width: 1023px)' srcset={shutdownTablet} />
        <source media='screen and (min-width: 1024px)' srcset={shutdownDekstop} />
        <img src={shutdownDekstop} alt='App In the Air is closing' className={style['responsive-image']} />
      </picture>
    </div>
  )
}
