import React, { useState } from 'react'
import './_question.sass'
import useRandomInteger from '../../hook/useRandomInteger'
import TypeCommunication from './_typeCommunication'

const AfterQuestion = ({ answer, cardColor, cardImage }) => {
  const [hide, setHide] = useState(false)
  const rotate = useRandomInteger(-3, 3)
  return (
    <div
      className={'question ' + (hide ? ' question-hide' : ' question-show')} style={{
        // При наличии ответа, юерем цвет и картинку из него, иначи задаем отдельно
        backgroundColor: cardColor,
        backgroundImage: `url(${cardImage})`,
        // Крутим карточку рандомом
        transform: `rotate(${rotate}deg)`
      }}
    >
      <div className='question__wrp'>
        <div className='question__text'>
          {answer.text}
        </div>
        {answer.subText &&
          <div className='question__subText'>
            {answer.subText}
          </div>}
        <div className='question__answer'>
          {/* Тип связи */}
          <TypeCommunication
            answerType={answer.answerType}
            type={answer.type}
            setHide={setHide}
          />
        </div>
      </div>

    </div>
  )
}

export default AfterQuestion
