import React from 'react'
import './style.sass'
import useWindowSize from '../../hook/useWindowSize'

const _renderLinks = () => {
  return (
    <div className='bg__links'>
      <a
        href='https://medium.com/app-in-the-air/app-in-the-air-meetup-in-seattle-wa-november-6-2018-de24c289f4b5'
        target='blank'
        className='bg__city bg__city-seattle'
      >
        Seattle
      </a>
      <a
        href='https://medium.com/app-in-the-air/app-in-the-air-meetup-new-york-city-june-27-2019-6524c1f7b8a5'
        target='blank'
        className='bg__city bg__city-ny'
      >
        New York
      </a>
      <a
        href='https://medium.com/app-in-the-air/app-in-the-air-meetup-london-912917e3571'
        target='blank'
        className='bg__city bg__city-london'
      >
        London
      </a>
      <span
        className='bg__city bg__city-la'
      >
        Los Angeles
      </span>
    </div>
  )
}

const CustomerAppreciationEvents = () => {
  const windowSize = useWindowSize()
  return (
    <div className='customerAppreciationEvents'>
      <div className='customerAppreciationEvents__bg'>
        {windowSize.desktop && _renderLinks()}
      </div>
      <div className='container'>
        <div className='customerAppreciationEvents__head'>
          Customer Appreciation Events
        </div>
        <div className='customerAppreciationEvents__text'>
          We host customer appreciation events for our top users around the world. Participants come from
          different professional backgrounds and also include flight crew, like pilots and attendants.
        </div>
        {windowSize.tablet && _renderLinks()}
      </div>
    </div>
  )
}

export default CustomerAppreciationEvents
