import React from 'react'
import './_mobileSlider.sass'
import phoneMain from '../../../assets/image/phones/phone1_top.png'
import phoneSlider1 from '../../../assets/image/phones/phone1_down.png'
import phoneSlider2 from '../../../assets/image/phones/phone2_down.png'
import phoneSlider3 from '../../../assets/image/phones/phone3_down.png'
import phoneSlider4 from '../../../assets/image/phones/phone4_down.png'
import Slider from 'react-slick'
import AppUrls from '../../../data/appsUrl'
import { logEvent } from '../../../analytics'
import { useInViewport } from '../../../hook/useInViewport'

const MobileSlider = () => {
  const appStoreRef = useInViewport(() => logEvent('aita_mp_store_main_topTextStoreButton_see', 'App Store'))
  const googlePlayRef = useInViewport(() => logEvent('aita_mp_store_main_topTextStoreButton_see', 'Google Play'))
  const galaxyStoreRef = useInViewport(() => logEvent('aita_mp_store_main_topTextStoreButton_see', 'Galaxy Store'))

  const handleClick = (name) => () => {
    logEvent('aita_mp_store_main_topTextStoreButton_tap', name)
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true
  }
  return (
    <div className='mobileSlider'>
      <div className='container'>
        <img src={phoneMain} alt='' className='mobileSlider__img' />
        <Slider {...settings}>
          <div className='mobileSlider__el'>
            <div className='mobileSlider__label'>🚨&nbsp;&nbsp;Real-time notifications</div>
            <img src={phoneSlider1} alt='' className='mobileSlider__img' />
          </div>
          <div className='mobileSlider__el'>
            <div className='mobileSlider__label'>👀&nbsp;&nbsp;Subscribe your family to updates</div>
            <img src={phoneSlider2} alt='' className='mobileSlider__img' />
          </div>
          <div className='mobileSlider__el'>
            <div className='mobileSlider__label'>🚀&nbsp;&nbsp;Auto check-in</div>
            <img src={phoneSlider3} alt='' className='mobileSlider__img' />
          </div>
          <div className='mobileSlider__el'>
            <div className='mobileSlider__label'>📱&nbsp;&nbsp;AR baggage scanner</div>
            <img src={phoneSlider4} alt='' className='mobileSlider__img' />
          </div>
        </Slider>
        <p className='descriptions__link link'>
          Download free on the <a href={AppUrls[0].url} target='blank' rel='opener' className='link__el' onClick={handleClick(AppUrls[0].name)} ref={appStoreRef}>{AppUrls[0].name}</a>, &nbsp;
          <a href={AppUrls[1].url} target='blank' rel='opener' className='link__el' onClick={handleClick(AppUrls[1].name)} ref={googlePlayRef}>{AppUrls[1].name}</a> or <a href={AppUrls[2].url} target='blank' rel='opener' className='link__el' onClick={handleClick(AppUrls[2].name)} ref={galaxyStoreRef}>{AppUrls[2].name}</a>
        </p>
      </div>
    </div>
  )
}

export default MobileSlider
