// import React from 'react'
import { ReactNode } from 'react'

export type HistoryItem = any
// eslint-disable-next-line no-shadow
export enum FlightsReviewViewState {
  SeatClass = 'SeatClass',
  FlightAdvantages = 'FlightAdvantages',
  FlightImprovements = 'FlightImprovements',
  FlightExperience = 'FlightExperience',
  FinalScreen = 'FinalScreen',
}
export enum seatClasses {
  EconomyClass = 'Economy Class',
  PremiumEconomy = 'Premium Economy',
  BusinessClass = 'Business Class',
  FirstClass = 'First Class'
}
export enum amenities {
  DepartureExperience = 'Departure Experience',
  ArrivalExperience = 'Arrival Experience',
  BookingExperience = 'Booking experience',
  Aircraft = 'Aircraft',
  Seat = 'Seat',
  Cleanliness = 'Cleanliness',
  FoodBeverage = 'Food & Beverage',
  CabinCrewService = 'Cabin Crew Service',
  IFE = 'In flight Entertainment',
  WiFi = 'Wi-Fi',
  Other = 'Other'
}

export enum TravelExperience {
  BookingExperience = 'booking_experience',
  DepartureExperience = 'departure_experience',
  Seat = 'seat',
  Cleanliness = 'cleanliness',
  CabinCrewService = 'cabin_crew_service',
  InFlightEntertainment = 'in_flight_entertainment',
  WiFi = 'wi_Fi',
  Aircraft = 'aircraft',
  ArrivalExperience = 'arrival_experience',
}

export const info: Record<TravelExperience, string> = {
  [TravelExperience.BookingExperience]: 'ease of booking, communication, refund process',
  [TravelExperience.DepartureExperience]: 'Airport check-in, boarding, airport facilities, lounge',
  [TravelExperience.Seat]: 'seat comfort, leg room, seat functionality, seat configuration',
  [TravelExperience.Cleanliness]: 'aircraft cabin, lavatory',
  [TravelExperience.CabinCrewService]: 'helpfulness, communication, attention to details',
  [TravelExperience.InFlightEntertainment]: 'content variety and quality, easiness to browse',
  [TravelExperience.WiFi]: 'speed and availability of Wi-Fi, availability of power port and USB port',
  [TravelExperience.Aircraft]: 'spaciousness, quietness, cabin pressure comfort',
  [TravelExperience.ArrivalExperience]: 'airport baggage delivery, amenities, accessibility'
}

export interface dataI {
   liked: amenities[] | [], needToBeImproved: amenities[] | [], feedback: string, seatClass: seatClasses | ''
}

export interface PagesProps {
  setCurrentPage: (currentPage: FlightsReviewViewState) => void,
  data: dataI,
  setData: (prev: dataI) => void,
  info?: {
    flight: string,
    date: string,
    segments: string,
    carrier: string,
    userCode: string
  },
  error?: null | string,
  setError?: () => void,
  setModalOpen?: (value: boolean) => void
}

export interface SeatClassCheckboxProps {
  title: seatClasses,
  onHandleChange: (title: seatClasses) => void,
  checked: boolean
}

export interface AmenityCheckboxProps {
  title: amenities,
  handleChange: (amenities: amenities) => void,
  hasFour: boolean,
  isChecked: boolean,
  index: string
}

export interface ParamsI {
  carrier: string,
  date: string,
  flight: string,
  segments: string
}

export interface QuestionProps {
  question?: string,
  info?: {flight: string, date: string, segments: string},
  checkedItems?: number,
  children?: ReactNode,
  carrier?: string,
  onWebViewClose?: () => void,
  userLocale?: string,
  type: string,

}

export interface AnswerProps {
  title?: string,
  children?: ReactNode,
  onNextClick?: (() => void),
  onBackClick?: (() => void),
  disabledNext?: boolean,
  form?: string,
  setModalOpen?: (value: boolean) => void
}
