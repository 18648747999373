import Api from '../../../api'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowPopup,
  setTypePopup,
  TYPE_CHOOSE_PAY,
  TYPE_ERROR,
  TYPE_SUCCESS
} from '../../paymentPopup/model'
import { setPaypalAccount, setShowLoader } from '../model'
import { setSubscriptionInfo } from '../../calculation/model'
import analytics from '../../../analytics'

const CHECK_INTERVAL = 50
const CONNECT_TIMEOUT = 60 * 1000
const WINDOW_WIDTH = 500
const WINDOW_HEIGHT = 700

export default class PayPalModel {
  constructor (onSuccess, onFailure) {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.userId = useSelector(state => state.calculation.userId)
    this.flights = useSelector(state => state.calculation.flights)
    this.stays = useSelector(state => state.calculation.stays)
    this.onSuccess = onSuccess
    this.onFailure = onFailure
  }

  connectPayPalUrl = () => {
    const baseurl = process.env.REACT_APP_PAYPAL_CONNECT_URL
    const data = {
      flowEntry: 'static',
      client_id: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      scope: 'openid email',
      response_type: 'code',
      redirect_uri: process.env.REACT_APP_PAYPAL_REDIRECT_URL
    }
    const qs = new URLSearchParams(data)
    return `${baseurl}?${qs.toString().replace(/\+/g, ' ')}`
  }

  handleClick = () => {
    const windowLeft = (window.screen.width / 2) - (WINDOW_WIDTH / 2)
    const windowTop = (window.screen.height / 2) - (WINDOW_HEIGHT / 2)
    const options = `width=${WINDOW_WIDTH}, height=${WINDOW_HEIGHT}, top=${windowTop}, left=${windowLeft}`
    const authWindow = window.open(this.connectPayPalUrl(), 'PayPal', options)
    analytics.logEvent('web_subs_applepay_paypal_show', `price ${process.env.REACT_APP_PAYMENT}`)
    this.dispatch(setShowLoader(true))
    /* Check when redirect is finally completed */
    const interval = setInterval(() => {
      let queryString = null
      try {
        queryString = authWindow.location.search /* would throw until all redirects will be completed */
      } catch (e) {
        // console.log('authWindowError', e)
        return
      }

      const params = new URLSearchParams(queryString)
      const isSucceeded = params.get('isSucceeded')
      if (!isSucceeded) {
        return
      } else if (isSucceeded === 'true') {
        analytics.logEvent('web_subs_applepay_paypal_login')
        this.onSuccess({
          isSucceeded: true,
          email: params.get('email'),
          payerId: params.get('payerId'),
          name: params.get('name')
        })
      } else if (isSucceeded === 'false') {
        analytics.logEvent('web_subs_applepay_paypal_error', params.get('error'))
        this.onFailure(new Error(params.get('error')))
      }

      authWindow.close()
      clearInterval(interval)
      clearTimeout(timeout)
      this.dispatch(setShowLoader(false))
    }, CHECK_INTERVAL)

    /* Timeout waiting for oauth */
    const timeout = setTimeout(() => {
      clearInterval(interval)
      this.onFailure(new Error('Failed to connect to your PayPal account. Try again.'))
      authWindow.close()
    }, CONNECT_TIMEOUT)
  }

  onCreateOrder = (data, actions) => {
    const res = actions.order.create({
      purchase_units: [
        {
          amount: {
            value: process.env.REACT_APP_PAYMENT
          }
        }
      ]
    })
    this.dispatch(setShowLoader(true))
    analytics.logEvent('web_subs_paypal_show', `price ${process.env.REACT_APP_PAYMENT}`)
    return res
  }

  handleOnApprove = (data, action) => {
    analytics.logEvent('web_subs_paypal_pay_success', `${this.userId};${process.env.REACT_APP_PAYMENT}`)
    action.order.get()
      .then(orderData => {
        const payment = {
          type: 'paypal',
          stripePayment: null,
          paypalPayment: orderData,
          paypalAccount: orderData.payer || null
        }
        this.api.subscriptionActivate(this.userId, payment)
          .then(result => {
            this.dispatch(setSubscriptionInfo(result))
            this.dispatch(setTypePopup(result.isActivated ? TYPE_SUCCESS : TYPE_ERROR))
            this.dispatch(setShowPopup(true))
            this.dispatch(setShowLoader(false))
          })
      })
  }

  handleOnCancel = (data, action) => {
    analytics.logEvent('web_subs_paypal_cancel')
    this.dispatch(setShowLoader(false))
  }

  handleOnError = (data, action) => {
    analytics.logEvent('web_subs_paypal_error', data)
    this.dispatch(setShowLoader(false))
  }

  handleSetPayPalAccount = (data) => {
    this.dispatch(setPaypalAccount(data))
    this.dispatch(setTypePopup(TYPE_CHOOSE_PAY))
    this.dispatch(setShowPopup(true))
    this.dispatch(setShowLoader(false))
  }
}
