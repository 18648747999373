const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
const PAYMENT_INTENT = 'PAYMENT_INTENT'
const SHOW_APPLE_PAY = 'SHOW_APPLE_PAY'
const defaultState = {
  paymentRequest: null,
  paymentIntent: null,
  showApplePay: true
}
const ApplePayModel = (state = defaultState, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return { ...state, paymentRequest: action.payload }
    case PAYMENT_INTENT:
      return { ...state, paymentIntent: action.payload }
    case SHOW_APPLE_PAY:
      return { ...state, showApplePay: action.payload }

    default:
      return state
  }
}

export default ApplePayModel

export const setPaymentRequest = (paymentRequest) => ({ type: PAYMENT_REQUEST, payload: paymentRequest })
export const setPaymentIntent = (paymentIntent) => ({ type: PAYMENT_INTENT, payload: paymentIntent })
export const setShowApplePay = (show) => ({ type: SHOW_APPLE_PAY, payload: show })
