import style from './style.module.sass'

export const RequestingRefund = () => {
  return (
    <section className={style.container}>
      <h2 className={style.title}>Requesting a subscription refund</h2>
      <p className={style.paragraph}>
        If you recently purchased a subscription from App in the Air you may be eligible for a refund. All refunds will be managed via the app store you used to make your purchase. We have provided links to the documentation for each store below.
      </p>
      <ul>
        <li className={style.paragraph}><a className={style.link} href='https://support.apple.com/en-us/118223'>Apple App Store</a></li>
        <li className={style.paragraph}><a className={style.link} href='https://support.google.com/googleplay/answer/2479637?hl=en'>Google Play Store</a></li>
        <li className={style.paragraph}><a className={style.link} href='https://www.samsung.com/latin_en/support/apps-services/can-i-get-a-refund-for-an-app-or-in-app-item/#helpHtg1callOrder0_0'>Samsung Galaxy Store</a></li>
      </ul>
    </section>
  )
}
