import React from 'react'
import { PayPalButtons } from '@paypal/react-paypal-js'
import Button from '../_button'
import PayPalModel from './model'
import analytics from '../../../analytics'

const PayPal = ({ color }) => {
  const payPalModel = new PayPalModel()

  return (
    <div
      className='payBlock'
    >
      <PayPalButtons
        className='payPalBtn'
        onClick={() => analytics.logEvent('web_subs_paypal_pay_click', `price ${process.env.REACT_APP_PAYMENT}`)}
        style={{ layout: 'horizontal', color: 'white', height: 48 }}
        createOrder={payPalModel.onCreateOrder}
        onApprove={payPalModel.handleOnApprove}
        onCancel={payPalModel.handleOnCancel}
        onError={payPalModel.handleOnError}
      />
      <Button
        text='Buy with PayPal'
        icon='payPal'
        color={color}
      />
    </div>
  )
}

export default PayPal
