import React from 'react'
import './style.sass'
import img from '../../assets/image/doRight.png'

const DoRight = () => {
  return (
    <div className='doRight'>
      <div className='container'>
        <img src={img} alt='' className='doRight__img' />
        <div className='doRight__desc'>
          <div className='head'>
            Do right <br />
            by the planet
          </div>
          <div className='text'>
            We are committed to the environment and take a pledge to plant one tree for every flight booked
            in the app. You can also become a carbon neutral traveler by offsetting the carbon impact of all
            previously taken flights. In fact, our users have already planted over 16,000 trees around the
            world.
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoRight
