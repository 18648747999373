import React from 'react'
import './style.sass'

const Button = React.forwardRef(({ className, style, text, href = undefined, onClick }, ref) => {
  if (href) {
    return (
      <a
        href={href}
        target='blank'
        className={'btn ' + className}
        style={style}
        ref={ref}
      >
        {text}
      </a>
    )
  }
  return (
    <button
      className={'btn ' + className}
      onClick={onClick}
      style={style}
      ref={ref}
    >
      {text}
    </button>
  )
})

export default Button
