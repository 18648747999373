import React from 'react'
import businessClassIcon from '../../assets/image/review/seats/ic_businessclass_24.svg'
import economyClassIcon from '../../assets/image/review/seats/ic_economyclass_24.svg'
import premiumEconomyClassIcon from '../../assets/image/review/seats/ic_premiumeconomyclass_24.svg'
import firstClassIcon from '../../assets/image/review/seats/ic_firstclass_24.svg'
import cn from 'classnames'
import style from './style.module.sass'
import { ReactSVG } from 'react-svg'
import { seatClasses, SeatClassCheckboxProps } from '../../types'

export const seatIcons = {
  'Economy Class': economyClassIcon,
  'Premium Economy': premiumEconomyClassIcon,
  'Business Class': businessClassIcon,
  'First Class': firstClassIcon
}

export const SeatCheckbox: React.FC<SeatClassCheckboxProps> = React.memo(
  ({ title = seatClasses.EconomyClass, onHandleChange, checked }) => {
    return (
      <label className={style.label} key={title}>
        <ReactSVG className={cn(style.img)} src={seatIcons[title]} />
        <input
          type='checkbox'
          className={style.seatClass}
          checked={checked}
          onChange={() => {
            onHandleChange(title)
          }}
        />
        <span>
          {title}
        </span>
      </label>
    )
  }
)
