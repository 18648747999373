import { useRef, useEffect } from 'react'

export const useInViewport = (cb) => {
  const ref = useRef(null)
  const mounted = useRef(false)

  // eslint-disable-next-line no-undef
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (!mounted.current && entry.isIntersecting) {
        mounted.current = true
        cb && cb()
      }
    }
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => { observer.disconnect() }
  }, [])

  return ref
}
