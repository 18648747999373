import React from 'react'
import './_teamEl.sass'

const TeamEl = ({ team }) => {
  return (
    <>
      <div className='teamEl'>
        <img src={team.img} alt='' className='teamEl__img' />
        <div className='teamEl__desc'>
          <div className='teamEl__white'>
            {team.name} <br />
            <small>{team.position}</small>
          </div>
          {team.favorite &&
            <div className='teamEl__gray'>{team.favorite.name}: {team.favorite.value}</div>}

        </div>

      </div>
    </>
  )
}

export default TeamEl
