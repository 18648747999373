import React from 'react'
import './style.sass'
import CommunityStoriesPhoto from '../../assets/image/CommunityStoriesPhoto.png'
import useWindowSize from '../../hook/useWindowSize'

const CommunityStories = () => {
  const windowSize = useWindowSize()
  return (
    <div className='communityStories'>
      <div className='container'>
        {windowSize.tablet &&
          <>
            <div className='story__subhead'>Community Stories</div>
            <div className='story__head'>
              Want to be a flight attendant?
            </div>
          </>}
        <div className='communityStories__photo'>
          <img src={CommunityStoriesPhoto} alt='' className='communityStories__photo-img' />
          <div className='communityStories__photo-name'>
            Ben Keller, flight attendant
          </div>
        </div>
        <div className='communityStories__story'>
          {windowSize.desktop &&
            <>
              <div className='story__subhead'>Community Stories</div>
              <div className='story__head'>
                Want to be a flight attendant?
              </div>
            </>}
          <div className='story__text'>
            My best advice is no matter which airline you choose, make sure it also fits your needs as a
            person and the company culture works too. Know that whatever path in aviation you choose it will
            always be exciting and extremely fulfilling. However, if you know it’s for you then don’t stop
            applying and interviewing until you get a job offer.
          </div>
          <div className='story__link'>
            Full story on <a href='https://medium.com/app-in-the-air/ben-know-that-whatever-path-in-aviation-you-choose-it-will-always-be-exciting-and-extremely-699b196ca23a' target='blank' rel='opener'>Medium.com</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityStories
