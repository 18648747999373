import React, { useState, useEffect } from 'react'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import Button from '../_button'
import Api from '../../../api'
import { useDispatch, useSelector } from 'react-redux'
import { setShowApplePay } from './model'
import { setSubscriptionInfo } from '../../calculation/model'
import { setShowPopup, setTypePopup, TYPE_ERROR, TYPE_SUCCESS } from '../../paymentPopup/model'
import analytics from '../../../analytics'

const CheckoutForm = ({ color }) => {
  const api = new Api()
  const stripe = useStripe()
  const dispatch = useDispatch()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [paymentIntent, setPaymentIntent] = useState(null)
  const userId = useSelector(state => state.calculation.userId)
  const paypalAccount = useSelector(state => state.payment.paypalAccount)
  const successfulPayment = (ev) => {
    const payment = {
      type: 'stripe',
      stripePayment: ev,
      paypalPayment: null,
      paypalAccount: paypalAccount || null
    }
    api.subscriptionActivate(userId, payment)
      .then(result => {
        dispatch(setSubscriptionInfo(result))
        dispatch(setTypePopup(result.isActivated ? TYPE_SUCCESS : TYPE_ERROR))
        dispatch(setShowPopup(true))
      })
  }

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Membership fee',
          amount: process.env.REACT_APP_PAYMENT * 100
        },
        requestPayerName: true,
        requestPayerEmail: true
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
          if (paymentIntent === null) {
            api.paymentIntent()
              .then(result => {
                if (result) {
                  setPaymentIntent(result)
                  pr.on('paymentmethod', async (ev) => {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success')
                    // Check if the PaymentIntent requires any actions and if so let Stripe.js
                    // handle the flow. If using an API version older than "2019-02-11" instead
                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                    analytics.logEvent('web_subs_applepay_pay_success', `${userId};${process.env.REACT_APP_PAYMENT}`)
                    successfulPayment(ev)
                  })
                } else {
                  setShowApplePay(false)
                }
              })
          }
        } else {
          setShowApplePay(false)
        }
      })
    }
  }, [stripe])

  if (paymentRequest && paymentIntent) {
    return (
      <>
        <PaymentRequestButtonElement
          onClick={() => {
            analytics.logEvent('web_subs_applepay_pay_click', `price ${process.env.REACT_APP_PAYMENT}`)
          }}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                height: '48px'
              }
            }
          }}
        />
        <Button
          text='Buy with'
          icon='applePay'
          color={color}
        />
      </>
    )
  }
  return null
}

export default CheckoutForm
