import React from 'react'
import './style.sass'
import Slider from 'react-slick'
import SmallPeople from './_smallPeople'
import BigPeople from './_bigPeople'
import PageSubHeaderText from '../pageSubHeaderText'

const CommunityPeopleSlider = ({ head, text, peoples, typeCard }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: typeCard === 'small' ? 5 : 3,
    slidesToScroll: typeCard === 'small' ? 5 : 3,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: typeCard === 'small' ? 3 : 1,
          slidesToScroll: typeCard === 'small' ? 3 : 1,
          arrows: false,
          variableWidth: true

        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: typeCard === 'small' ? 3 : 1,
          slidesToScroll: typeCard === 'small' ? 3 : 1,
          arrows: false,
          variableWidth: true

        }
      }
    ]
  }
  return (
    <div className='communityPeopleSlider'>
      <div className='container'>
        <PageSubHeaderText text={head} />
        <div className='communityPeopleSlider__text' dangerouslySetInnerHTML={{ __html: text }} />
        <Slider {...settings}>
          {peoples.map((people, index) => {
            return (
              typeCard === 'small'
                ? <SmallPeople key={'communitySmallPeopleSlider' + index} people={people} />
                : <BigPeople key={'communityBigPeopleSlider' + index} people={people} />
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default CommunityPeopleSlider
