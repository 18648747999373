import React from 'react'
import './style.sass'
import Badge from '../badge'
import howItWork1 from '../../assets/image/subscription/howItWork1.png'
import howItWork2 from '../../assets/image/subscription/howItWork2.png'
import analytics from '../../analytics'

const SubscriptionList = () => {
  return (
    <div className='subscriptionList'>
      <div className='subscriptionList-items'>
        <div className='item'>
          <span className='item-number'>1</span>
          <span className='item-text'>
            Get started with a {process.env.REACT_APP_PAYMENT_DISCOUNT}% discount for ${process.env.REACT_APP_PAYMENT} per year. <br />
            You can cancel anytime
          </span>
        </div>
        <div className='item'>
          <span className='item-number'>2</span>
          <span className='item-text'>
            Book flights and hotels <br />
            with App in the Air
            <Badge
              className='badge__one'
              color='#32D74B'
              textColor='#fff'
              text='get cash'
            />
          </span>
        </div>
        <div className='item'>
          <span className='item-number'>3</span>
          <span className='item-text'>
            Receive your cashback via PayPal <br />
            If you have any questions, we have <a href='https://support.appintheair.com/hc/en-us/categories/1500000131661-Pilots-FAQ' target='blank' onClick={() => analytics.logEvent('web_subs_faq')}>FAQ</a>
          </span>
          <Badge
            className='badge__two'
            color='#FFD60A'
            textColor='#000'
            text='book for the loved ones'
          />
          <Badge
            className='badge__three'
            color='#FF453A'
            textColor='#000'
            text='earn on every trip'
          />
        </div>
      </div>
      <div className='subscriptionList-img'>
        <Badge
          className='badge__five'
          color='#32D74B'
          textColor='#fff'
          text='$2 a month, like a ☕️'
        />
        <img src={howItWork1} alt='' className='subscriptionList-img__one' />
        <img src={howItWork2} alt='' className='subscriptionList-img__two' />
        <Badge
          className='badge__four'
          color='#FFD60A'
          textColor='#000'
          text='Spend your cash as you wish'
        />
      </div>
    </div>
  )
}

export default SubscriptionList
