import Aleksandrs from '../assets/image/teams/Aleksandrs.jpg'
import AlexeyC from '../assets/image/teams/AlexeyC.jpg'
import AlexeyK from '../assets/image/teams/AlexeyK.jpg'
import Alla from '../assets/image/teams/Alla.jpg'
import Andrea from '../assets/image/teams/Andrea.jpg'
import Andrew from '../assets/image/teams/Andrew.jpg'
import Andy from '../assets/image/teams/Andy.jpg'
import budruev from '../assets/image/teams/budruev.jpg'
import Daria from '../assets/image/teams/Daria.jpg'
import Evan from '../assets/image/teams/Evan.jpg'
import Greg from '../assets/image/teams/Greg.jpg'
import Imran from '../assets/image/teams/Imran.jpg'
import Nicholas from '../assets/image/teams/Nicholas.jpg'
import Nikita from '../assets/image/teams/Nikita.jpg'
import NikitaK from '../assets/image/teams/NikitaK.jpg'
import Oleg from '../assets/image/teams/Oleg.jpg'
import Timur from '../assets/image/teams/Timur.jpg'
import Vitaly from '../assets/image/teams/Vitaly.jpg'
import Vladimir from '../assets/image/teams/Vladimir.jpg'
import Vladislav from '../assets/image/teams/Vladislav.jpg'
import VladV from '../assets/image/teams/Vlad_V.jpg'
import Olga from '../assets/image/teams/Olga.jpg'
import EvgeniiaMochalova from '../assets/image/teams/Evgeniia_Mochalova.jpg'
import VladimirChetverikov from '../assets/image/teams/Vladimir_Chetverikov.jpg'
import ValeriyShtaits from '../assets/image/teams/Valeriy_Shtaits.jpg'
import Marianna from '../assets/image/teams/Marianna.jpg'
import Devis from '../assets/image/teams/Devis.jpg'
import Ragif from '../assets/image/teams/Ragif.jpg'
import AlexandraSidorova from '../assets/image/teams/AlexandraSidorova.jpeg'
import AntonDryakhlykh from '../assets/image/teams/AntonDryakhlykh.png'
import BorisAneli from '../assets/image/teams/BorisAneli.jpeg'
import DavidBaurRay from '../assets/image/teams/DavidBaur-Ray.jpg'
import MikhailDyma from '../assets/image/teams/MikhailDyma.jpg'
import MilaPankratova from '../assets/image/teams/MilaPankratova.jpeg'
import RavilNazipov from '../assets/image/teams/RavilNazipov.jpeg'
import SergeyBakin from '../assets/image/teams/SergeyBakin.jpeg'
import SergeyPeshkov from '../assets/image/teams/SergeyPeshkov.jpeg'
import VladaCherepanova from '../assets/image/teams/VladaCherepanova.jpeg'

const Teams = [
  {
    name: 'Greg',
    img: Greg,
    position: 'CEO',
    favorite: {
      name: 'Favorite airport',
      value: 'SEA'
    }
  },
  {
    name: 'Nikita',
    img: NikitaK,
    position: 'B2B CPO',
    favorite: {
      name: 'Favorite airport',
      value: 'SIN'
    }
  },
  {
    name: 'Timur',
    img: Timur,
    position: 'Consultant',
    favorite: {
      name: 'Favorite flight time',
      value: 'Morning'
    }
  },
  {
    name: 'Andy',
    img: Andy,
    position: 'Vice President Sales and Strategic Partnerships',
    favorite: {
      name: 'Favorite airport',
      value: 'PDX'
    }
  },
  {
    name: 'Alexey, PhD',
    img: AlexeyK,
    position: 'Head Of Data Science',
    favorite: {
      name: 'Favorite aircraft',
      value: 'Airbus A380'
    }
  },
  {
    name: 'Evan',
    img: Evan,
    position: 'General Counsel',
    favorite: {
      name: 'Favorite flight time',
      value: 'Midday'
    }
  },
  {
    name: 'Nicholas',
    img: Nicholas,
    position: 'IT Director',
    favorite: {
      name: 'Favorite food onboard',
      value: 'Fruit'
    }
  },
  {
    name: 'Aleksandrs',
    img: Aleksandrs,
    position: 'Head of Customer Success',
    favorite: {
      name: 'Favorite aircraft',
      value: 'Airbus A380'
    }
  },
  {
    name: 'Alla',
    img: Alla,
    position: '2D/3D Designer',
    favorite: {
      name: 'Favorite destination',
      value: 'Japan'
    }
  },
  {
    name: 'Andrea',
    img: Andrea,
    position: 'Director of Operations and Finance',
    favorite: {
      name: 'Usually travels',
      value: 'with friends'
    }
  },
  {
    name: 'David',
    img: DavidBaurRay,
    position: 'Marketing Manager',
    favorite: {
      name: 'Favorite flight time',
      value: 'early in the morning'
    }
  },
  {
    name: 'Daria',
    img: Daria,
    position: 'Director of Austrian Office',
    favorite: {
      name: 'Favorite travel month',
      value: 'July'
    }
  },
  {
    name: 'Alexandra',
    img: AlexandraSidorova,
    position: 'Team assistant',
    favorite: {
      name: 'Favorite destination',
      value: 'Spain'
    }
  },
  {
    name: 'Mila',
    img: MilaPankratova,
    position: 'HR_manager',
    favorite: {
      name: 'Favorite destination',
      value: 'South Korea'
    }
  },
  {
    name: 'Ragif',
    img: Ragif,
    position: 'Team lead QA',
    favorite: {
      name: 'Favorite destination',
      value: 'USA'
    }
  },
  {
    name: 'Mikhail',
    img: MikhailDyma,
    position: 'QA Engineer',
    favorite: {
      name: 'Favorite food onboard',
      value: 'chocolate'
    }
  },
  {
    name: 'Nikita',
    img: Nikita,
    position: 'Senior Backend Engineer',
    favorite: {
      name: 'Favorite airport',
      value: 'DME'
    }
  },
  {
    name: 'Ravil',
    img: RavilNazipov,
    position: 'Backend Developer',
    favorite: {
      name: 'Favorite in-flight entertainment',
      value: 'sleep'
    }
  },
  {
    name: 'Alexey',
    img: AlexeyC,
    position: 'Android Developer',
    favorite: {
      name: 'Favorite in-flight entertainment',
      value: 'Movies'
    }
  },
  {
    name: 'Vladislav',
    img: budruev,
    position: 'iOS Team Lead',
    favorite: {
      name: 'Favorite airport',
      value: 'NCE'
    }
  },
  {
    name: 'Oleg',
    img: Oleg,
    position: 'iOS Developer',
    favorite: {
      name: 'Favorite travel month',
      value: 'March'
    }
  },
  {
    name: 'Anton',
    img: AntonDryakhlykh,
    position: 'iOS Developer',
    favorite: {
      name: 'Favorite destination',
      value: 'Moscow'
    }
  },
  {
    name: 'Boris',
    img: BorisAneli,
    position: 'iOS Developer',
    favorite: {
      name: 'Favorite airport',
      value: 'Doha International Airport (DIA)'
    }
  },
  {
    name: 'Vladislav',
    img: Vladislav,
    position: 'Customer Support Agent',
    favorite: {
      name: 'Favorite aircraft',
      value: 'Boeing 787'
    }
  },
  {
    name: 'Imran',
    img: Imran,
    position: 'Android Team Lead ',
    favorite: {
      name: 'Favorite airport',
      value: 'DXB'
    }
  },
  {
    name: 'Vladimir',
    img: Vladimir,
    position: 'Customer Support Agent',
    favorite: {
      name: 'Favorite in-flight entertainment',
      value: 'Movies'
    }
  },
  {
    name: 'Andrew',
    img: Andrew,
    position: 'Booking Backend Team Lead',
    favorite: {
      name: 'Favorite destination',
      value: 'Barcelona'
    }
  },
  {
    name: 'Sergey',
    img: SergeyBakin,
    position: 'Backend Developer',
    favorite: {
      name: 'Favorite travel month',
      value: 'October'
    }
  },
  {
    name: 'Sergey',
    img: SergeyPeshkov,
    position: 'Backend Developer',
    favorite: {
      name: 'Favorite flight time',
      value: 'night, to see the cities lights'
    }
  },
  {
    name: 'Vitaly',
    img: Vitaly,
    position: 'Backend Engineer',
    favorite: {
      name: 'Favorite destination',
      value: 'London'
    }
  },
  {
    name: 'Vladislav',
    img: VladV,
    position: 'Frontend Team Lead',
    favorite: {
      name: 'Favorite aircraft',
      value: 'Cessna 172'
    }
  },
  {
    name: 'Vlada',
    img: VladaCherepanova,
    position: 'Frontend Developer',
    favorite: {
      name: 'Favorite food onboard',
      value: 'coffee'
    }
  },
  {
    name: 'Olga',
    img: Olga,
    position: 'Travel Agent',
    favorite: {
      name: 'Favorite in-flight entertainment',
      value: 'Sleep'
    }
  },
  {
    name: 'Evgeniia',
    img: EvgeniiaMochalova,
    position: 'Designer Lead',
    favorite: {
      name: 'Favorite destination',
      value: 'Asia'
    }
  },
  {
    name: 'Vladimir',
    img: VladimirChetverikov,
    position: 'Analyst',
    favorite: {
      name: 'Favorite food onboard',
      value: 'Tomato juice'
    }
  },
  {
    name: 'Valeriy',
    img: ValeriyShtaits,
    position: 'Android Developer',
    favorite: {
      name: 'Usually travels',
      value: 'with friends'
    }
  },
  {
    name: 'Marianna',
    img: Marianna,
    position: 'Designer',
    favorite: {
      name: 'Favorite traveling month',
      value: 'June'
    }
  },
  {
    name: 'Devis',
    img: Devis,
    position: 'Backend Developer',
    favorite: {
      name: 'Favorite airport',
      value: 'PEE'
    }
  }
]

export default Teams
