import React from 'react'
import Questionnaire from '../component/questionnaire'
import CommunityPeopleSlider from '../component/communityPeopleSlider'
import CommunityPeople from '../data/communitePeople'
import Translation from '../component/translation'
import CustomerAppreciationEvents from '../component/customerAppreciationEvents'
import CommunityStories from '../component/communityStories'
import OurBlog from '../component/ourBlog'
import MiniStory from '../component/miniStoory'
import userTrips from '../data/userTrips'
import TipsCount from '../component/tipsCount'
import { Helmet } from 'react-helmet'

const CommunityPage = () => {
  return (
    <div className='page page-community'>
      <Helmet>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <Questionnaire />
      <CommunityPeopleSlider
        head='Translators'
        text='Our users have helped us translate App in the Air into several languages. Thanks for them, we’ve been able to make the app accessible to more travelers around the world.'
        peoples={CommunityPeople.translators}
        typeCard='big'
      />
      <Translation />
      <CommunityPeopleSlider
        head='Bug-reporters'
        text='Have you found a bug in the app recently? Tell us about it! These users have done so and in turn made App in the Air better for everyone.'
        peoples={CommunityPeople.bugReporters}
        typeCard='small'
      />
      <CustomerAppreciationEvents />
      <CommunityStories />
      <OurBlog />
      <MiniStory
        head='User tips from around the world'
        text='Did you know you can write reviews for other travelers in the app after your flight? <br/> Here are some of the latest tips from our community. '
        miniStory={userTrips}
        darkMode
      />
      <TipsCount />
    </div>
  )
}

export default CommunityPage
