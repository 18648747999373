import React from 'react'
import './style.sass'
import PageHeaderText from '../pageHeaderText'
import Questions from './_questions'

const Questionnaire = () => {
  return (
    <div className='questionnaire'>
      <div className='container'>
        <div className='questionnaire__desc'>
          <PageHeaderText text='Community <br/> in the Air' />
          <div className='text'>
            We're thrilled to have a community
            of millions of users around the world.
            Want to join the fun? Answer these five questions to find out what's in store!
          </div>
        </div>
        <Questions />
      </div>
    </div>
  )
}

export default Questionnaire
