import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useSelector } from 'react-redux'
import replaceSpacesWithUnderscores from '../../hook/replaceSpacesWithUnderscores'
import './style.sass'

const PrivacyFloat = () => {
  const sections = useSelector((state) => state.privacy.sections)
  const currentSectionHash = `#${useSelector((state) => state.privacy.currentSection)}`

  const items = sections.map((i) => {
    const href = `#${replaceSpacesWithUnderscores(i.attributes.title)}`
    return (
      <AnchorLink
        key={i.id}
        offset='100'
        href={href}
        className={`privacyFloat__item ${
          currentSectionHash === href ? 'privacyFloat__item-active' : ''
        }`}
      >
        {i.attributes.title}
      </AnchorLink>
    )
  })

  return (
    <div className='privacyFloat'>
      <div className='privacyFloat__list'>{items}</div>
    </div>
  )
}
export default PrivacyFloat
