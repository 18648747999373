import React, { useEffect, useState } from 'react'
import phone1Top from '../../../assets/image/phones/phone1_top.png'
import phone1Down from '../../../assets/image/phones/phone1_down.png'
import phone2Top from '../../../assets/image/phones/phone2_top.png'
import phone2Down from '../../../assets/image/phones/phone2_down.png'
import phone3Top from '../../../assets/image/phones/phone3_top.png'
import phone3Down from '../../../assets/image/phones/phone3_down.png'
import phone4Top from '../../../assets/image/phones/phone4_top.png'
import phone4Down from '../../../assets/image/phones/phone4_down.png'
import AppUrls from '../../../data/appsUrl'
import { logEvent } from '../../../analytics'
import { useInViewport } from '../../../hook/useInViewport'

const Slider = () => {
  const appStoreRef = useInViewport(() => logEvent('aita_mp_store_main_topTextStoreButton_see', 'App Store'))
  const googlePlayRef = useInViewport(() => logEvent('aita_mp_store_main_topTextStoreButton_see', 'Google Play'))
  const galaxyStoreRef = useInViewport(() => logEvent('aita_mp_store_main_topTextStoreButton_see', 'Galaxy Store'))
  const [active, setActive] = useState(3)
  const handleClick = (name) => () => {
    logEvent('aita_mp_store_main_topTextStoreButton_tap', name)
  }

  // Переключаем активный слайд
  useEffect(() => {
    const handleScroll = () => {
      // Находим слайдер
      const slider = document.getElementById('slider')
      // Находим его положение от верха
      const sliderTotal = slider.offsetTop + 225
      // узнаем текущий скролл
      const windowScroll = window.scrollY || 1
      // берем отношение положения к скролу
      let attitude = ~~(sliderTotal / windowScroll)
      // Некие ограничения по кол-во слайдов
      if (attitude > 3) attitude = 3
      if (attitude < 0) attitude = 0
      // ставим активный слайд
      setActive(attitude)
    }
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [active])

  return (
    <div className='phonesSlider' id='slider'>
      <div className='phones'>
        <div className={'phone ' + (active === 3 && 'phone-active')}>
          <img src={phone1Top} alt='' className='phone__top' />
          <img src={phone1Down} alt='' className='phone__down' />
        </div>
        <div className={'phone ' + (active === 2 && 'phone-active')}>
          <img src={phone2Top} alt='' className='phone__top' />
          <img src={phone2Down} alt='' className='phone__down' />
        </div>
        <div className={'phone ' + (active === 1 && 'phone-active')}>
          <img src={phone3Top} alt='' className='phone__top' />
          <img src={phone3Down} alt='' className='phone__down' />
        </div>
        <div className={'phone ' + (active === 0 && 'phone-active')}>
          <img src={phone4Top} alt='' className='phone__top' />
          <img src={phone4Down} alt='' className='phone__down' />
        </div>
      </div>

      <div className='descriptions'>
        <ul className='descriptions__list list'>
          <li className={'list__el ' + (active === 3 && 'list__el-active')}>🚨&nbsp;&nbsp;Real-time notifications</li>
          <li className={'list__el ' + (active === 2 && 'list__el-active')}>👀&nbsp;&nbsp;Subscribe your family to updates</li>
          <li className={'list__el ' + (active === 1 && 'list__el-active')}>🚀&nbsp;&nbsp;Auto check-in</li>
          <li className={'list__el ' + (active === 0 && 'list__el-active')}>📱&nbsp;&nbsp;AR baggage scanner</li>
        </ul>
        <p className='descriptions__link link'>
          Download free on the <a href={AppUrls[0].url} target='blank' rel='opener' className='link__el' onClick={handleClick(AppUrls[0].name)} ref={appStoreRef}>{AppUrls[0].name}</a>,
          <br />
          <a href={AppUrls[1].url} target='blank' rel='opener' className='link__el' onClick={handleClick(AppUrls[1].name)} ref={googlePlayRef}>{AppUrls[1].name}</a> or <a href={AppUrls[2].url} target='blank' rel='opener' className='link__el' onClick={handleClick(AppUrls[2].name)} ref={galaxyStoreRef}>{AppUrls[2].name}</a>
        </p>
      </div>
    </div>
  )
}

export default Slider
