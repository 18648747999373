import React from 'react'
import './style.sass'

const HaveSubscription = () => {
  return (
    <div className='haveSubscription'>
      <div className='haveSubscription__head'>
        Your subcription is active!
      </div>
      <div className='haveSubscription__text'>
        Book flights and hotels with App in the Air and receiver your cashback
      </div>
    </div>
  )
}

export default HaveSubscription
