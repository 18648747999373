import React from 'react'
import './style.sass'
import ReviewsData from '../../data/reviews'
import Review from './_review'
import Slider from 'react-slick'

const Reviews = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: true

        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true

        }
      }
    ]
  }
  return (
    <div className='reviews'>
      <div className='container'>
        <Slider {...settings}>
          {ReviewsData.map(review => {
            return (
              <Review key={review.name} review={review} />
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default Reviews
