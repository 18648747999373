const ItemHeader = ({ setIsOpen, title, id }) => {
  return (
    <div
      className='privacyList__item-header' onClick={() => setIsOpen(prev => {
        return prev === id ? false : id
      })}
    >
      <p className='privacyList__item-title'>
        {title}
      </p>
      <svg className='privacyList__item-plus' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M7 9V16H9V9H16V7H9V0H7V7H0V9H7Z' fill='#F8F8F8' />
      </svg>
    </div>
  )
}

export default ItemHeader
