const USER_ID = 'USER_ID'
const SUBSCRIPTION_INFO = 'SUBSCRIPTION_INFO'
const FLIGHTS = 'FLIGHTS'
const STAYS = 'STAYS'
const defaultState = {
  userId: null,
  subscriptionInfo: null,
  flights: '2-4',
  stays: '1-2'
}
const CalculationModel = (state = defaultState, action) => {
  switch (action.type) {
    case USER_ID:
      return { ...state, userId: action.payload }
    case SUBSCRIPTION_INFO:
      return { ...state, subscriptionInfo: action.payload }
    case FLIGHTS:
      return { ...state, flights: action.payload }
    case STAYS:
      return { ...state, stays: action.payload }
    default:
      return state
  }
}

export default CalculationModel

export const setUserId = (userId) => ({ type: USER_ID, payload: userId })
export const setSubscriptionInfo = (subscriptionInfo) => ({ type: SUBSCRIPTION_INFO, payload: subscriptionInfo })
export const setFlights = (flights) => ({ type: FLIGHTS, payload: flights })
export const setStays = (stays) => ({ type: STAYS, payload: stays })
