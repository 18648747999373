import style from './style.module.sass'

export const PurchasedTravel = () => {
  return (
    <section className={style.container}>
      <h2 className={style.title}>Purchased Travel, insurance and E-SIMs</h2>
      <p className={style.paragraph}>
        All purchased air and hotel bookings, travel insurance policies and e-sim data will remain valid for use.
      </p>
    </section>
  )
}
