import style from './style.module.sass'
import React from 'react'
import { Question } from '../../component/question'
import { Answer } from '../../component/answer'
import { SeatCheckbox } from '../../component/seatCheckbox/index'
import { FlightsReviewViewState, PagesProps, seatClasses } from '../../types'
import { useFlightReviewQuery } from '../../hook/useFlightReviewQuery'

export const SeatClass: React.FC<PagesProps> = ({ setCurrentPage, data, setData, error }) => {
  const info = useFlightReviewQuery()
  const handleChange = (title: seatClasses) => {
    if (data.seatClass === title) {
      setData(prev => ({ ...prev, seatClass: '' }))
    } else {
      setData(prev => ({ ...prev, seatClass: title }))
    }
  }

  return (
    <>
      <Question
        info={info}
        question='What was the seat class during your flight?'
        checkedItems={1}
        carrier={`https://storage.googleapis.com/iappintheair.appspot.com/Airlines/SquaredLogos/${info.carrier}.png`}
        type=''
      />
      <Answer
        title='Select your seat class'
        disabledNext={!data.seatClass}
        onNextClick={() => {
          setCurrentPage(FlightsReviewViewState.FlightAdvantages)
        }}
      >
        <div className={style.checkboxes}>
          <SeatCheckbox
            checked={data.seatClass === seatClasses.EconomyClass}
            title={seatClasses.EconomyClass}
            onHandleChange={handleChange}
          />
          <SeatCheckbox
            checked={data.seatClass === seatClasses.PremiumEconomy}
            title={seatClasses.PremiumEconomy}
            onHandleChange={handleChange}
          />
          <SeatCheckbox
            checked={data.seatClass === seatClasses.BusinessClass}
            title={seatClasses.BusinessClass}
            onHandleChange={handleChange}
          />
          <SeatCheckbox
            checked={data.seatClass === seatClasses.FirstClass}
            title={seatClasses.FirstClass}
            onHandleChange={handleChange}
          />
        </div>
      </Answer>
    </>
  )
}
