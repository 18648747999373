import { Thanks } from '../component/thanks'
import { DownloadData } from '../component/downloadData'
import { LandingSpot } from '../component/landingSpot'
import { PurchasedTravel } from '../component/purchasedTravel'
import { RequestingRefund } from '../component/requestingRefund'
import { ShutdownImage } from '../component/shutdownImage'

const ShutdownPage = () => {
  return (
    <div className='page'>
      <div className='container'>
        <ShutdownImage />
        <Thanks />
        <DownloadData />
        <RequestingRefund />
        <PurchasedTravel />
        <LandingSpot />
      </div>
    </div>
  )
}

export default ShutdownPage
