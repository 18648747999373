const SECTIONS = 'SECTIONS'
const CURRENT_SECTIONS = 'CURRENT_SECTIONS'
const HEADER = 'HEADER'

const defaultState = {
  header: {},
  sections: [],
  currentSection: ''
}
const PrivacyModel = (state = defaultState, action) => {
  switch (action.type) {
    case SECTIONS:
      return { ...state, sections: [...state.sections, ...action.payload] }
    case HEADER:
      return { ...state, header: { ...state.header, ...action.payload } }
    case CURRENT_SECTIONS:
      return { ...state, currentSection: action.payload }
    default:
      return state
  }
}

export default PrivacyModel

export const setSectionsData = (data) => ({ type: SECTIONS, payload: data })

export const setCurrentSection = (data) => ({ type: CURRENT_SECTIONS, payload: data })

export const setHeaderData = (data) => ({ type: HEADER, payload: data })
