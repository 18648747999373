import React from 'react'
import PayPal from '../payButtons/payPal/payPal'
import { useDispatch, useSelector } from 'react-redux'
import ApplePay from '../payButtons/applePay/applePay'
import { setTypePopup, TYPE_PAYPAL_CONNECT } from './model'

const TypeChoosePay = () => {
  const paypalAccount = useSelector(state => state.payment.paypalAccount)
  const dispatch = useDispatch()
  const onCLick = (e) => {
    e.preventDefault()
    dispatch(setTypePopup(TYPE_PAYPAL_CONNECT))
  }
  return (
    <>
      <div className='popup__head'>
        PayPal wallet <span className='no-wrap no-wrap-hidden'>{paypalAccount ? paypalAccount.email : ''}</span> is ready for cashback
      </div>
      <div className='popup__text'>
        Activate exclusive subscription for a discounted rate of $ {process.env.REACT_APP_PAYMENT} per year. <a href='#' onClick={onCLick}>Not yours account?</a>
      </div>
      <div className='popup__btn leftCorrect'>
        <PayPal color='blue' />
        <ApplePay color='black' />
      </div>
    </>
  )
}

export default TypeChoosePay
