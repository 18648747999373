import React from 'react'
import './style.sass'
import PageHeaderText from '../pageHeaderText'
import { logEvent } from '../../analytics'
import { useInViewport } from '../../hook/useInViewport'

const Translation = () => {
  const ref = useInViewport(() => logEvent('aita_cp_store_main_writeUsButton_see', 'translator'))

  return (
    <div className='translation'>
      <div className='container'>
        <PageHeaderText text='App in the Air has been <br/> translated into 15 languages.' />
        <div className='translation__text'>
          Want to become a translator? <a href='mailto:support@appintheair.com' target='blank' rel='opener' onClick={() => logEvent('aita_cp_store_main_writeUsButton_tap', 'translator')} ref={ref}>Write to us</a>.
        </div>
      </div>
    </div>
  )
}

export default Translation
