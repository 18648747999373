import React from 'react'
import { useSelector } from 'react-redux'
import Moment from 'react-moment'
import Button from '../payButtons/_button'

const TypeSuccess = ({ closePopup }) => {
  const subscriptionInfo = useSelector(state => state.calculation.subscriptionInfo)
  return (
    <>
      <div className='popup__head'>
        Your subscription is active!
      </div>
      <div className='popup__text'>
        Book with App in the Air and get cashback till
        <Moment format=' DD MMM YYYY '>
          {subscriptionInfo.endDate}
        </Moment>
        . Cashback will be transferred to <span className='no-wrap'>{subscriptionInfo.cashbackAccount}</span> PayPal account.
      </div>
      <div className='popup__btn'>
        <Button
          onClick={closePopup}
          text='Great'
          icon='popup__button'
        />
      </div>
    </>
  )
}

export default TypeSuccess
