import React from 'react'
import './style.sass'
import logo from '../../../assets/image/logo.svg'
import { Link } from 'react-router-dom'

const SubscriptionBanner = () => {
  return (
    <div className='subscriptionBanner'>
      <div className='container'>
        <Link to='/'>
          <img src={logo} alt='' className='logo' />
        </Link>
        <div className='subscriptionBanner-text'>
          <small className='text-small'>
            You are invited to join App in the Air's exclusive Travel Membership Program
          </small>
          Travel and get rewarded for it
        </div>
      </div>
    </div>
  )
}

export default SubscriptionBanner
