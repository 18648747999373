import React from 'react'
import './style.sass'

const Badge = ({ text, color, textColor, className }) => {
  return (
    <div className={'badge ' + className} style={{ backgroundColor: color, color: textColor }}>
      {text}
    </div>
  )
}

export default Badge
