import style from './style.module.sass'
import React, { useEffect } from 'react'
import success from '../../assets/image/finalPage/success.svg'
import failure from '../../assets/image/finalPage/failure.svg'
import { ReactSVG } from 'react-svg'
import { useParams } from 'react-router-dom'
import usePostMessage from '../../hook/usePostMessage'

const FinalPage = () => {
  const { status } = useParams()
  if (status !== 'success' && status !== 'failure') return null
  useEffect(() => {
    usePostMessage('eSim', status)
  }, [])
  return (
    <div className={style.final}>
      <ReactSVG src={status === 'success' ? success : failure} />
      <p className={style.final__text}>{status === 'success' ? 'Success' : 'Something went wrong'}</p>
    </div>
  )
}

export default FinalPage
