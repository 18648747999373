import style from '../style.module.sass'
import { AmenityCheckbox } from '../../component/amenityCheckbox/index'
import { Question } from '../../component/question'
import { Answer } from '../../component/answer'
import React from 'react'
import { FlightsReviewViewState, PagesProps, amenities } from '../../types'
import { useFlightReviewQuery } from '../../hook/useFlightReviewQuery'
import _ from 'lodash'

export const FlightAdvantages: React.FC<PagesProps & 'setModalOpen'> = ({ setCurrentPage, data, setData, error, setModalOpen }) => {
  const info = useFlightReviewQuery()
  const handleChange = (title: amenities) => {
    setData(prev => {
      if (prev.liked.includes(title)) {
        return { ...prev, liked: prev.liked.filter(el => el !== title) }
      } else {
        return { ...prev, liked: [...prev.liked, title] }
      }
    })
  }

  return (
    <div className={style.flightAdvantages}>
      <Question
        info={info}
        question='What did you like about your flight?'
        checkedItems={2}
        carrier={`https://storage.googleapis.com/iappintheair.appspot.com/Airlines/SquaredLogos/${info.carrier}.png`}
        type=''
      />
      <Answer
        title='Select up to four options'
        setModalOpen={setModalOpen}
        onBackClick={() => {
          setCurrentPage(FlightsReviewViewState.SeatClass)
        }}
        onNextClick={() => {
          setCurrentPage(FlightsReviewViewState.FlightImprovements)
        }}
      >
        <div className={style.checkboxes}>
          {_.map(_.filter(amenities, amenity => amenity !== 'Other'), (amenity) =>
            <AmenityCheckbox
              isChecked={Boolean(data.liked.find(el => el === amenity))}
              hasFour={data.liked.length === 4}
              handleChange={handleChange}
              title={amenity}
            />
          )}
          <AmenityCheckbox
            isChecked={Boolean(data.liked.find(el => el === amenities.Other))}
            hasFour={data.liked.length === 4}
            handleChange={handleChange}
            title={amenities.Other}
          />
        </div>
      </Answer>
    </div>
  )
}
