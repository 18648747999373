import React from 'react'
import KitCut from '../kitCut'
import './_bigPeople.sass'

const BigPeople = ({ people }) => {
  return (
    <div className='bigPeople'>
      <div className='bigPeople__top'>
        <img src={people.photo} alt='' className='bigPeople__photo' />
        <div className='bigPeople__desc'>
          <div className='desc__top'>
            <div className='bigPeople__name'>{people.name}</div>
            <div className='bigPeople__profession'>{people.profession}</div>
          </div>
          <div className='desc__bottom'>
            <div className='bigPeople__translated'>
              Translated <br />
              into {people.lang}
            </div>
          </div>
        </div>
        <div className='bigPeople__flag'>
          <img src={people.flag} alt='' />
        </div>
      </div>
      <div className='bigPeople__text'>
        <KitCut review={people.text} initLimit={250} />
      </div>
    </div>
  )
}

export default BigPeople
