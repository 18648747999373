import React, { useState, useEffect } from 'react'
import PayPal from './payPal/payPal'
import './style.sass'
import Button from './_button'
import { setShowPopup, setTypePopup, TYPE_PAYPAL_CONNECT } from '../paymentPopup/model'
import { useDispatch } from 'react-redux'
import { useStripe } from '@stripe/react-stripe-js'
import analytics from '../../analytics'

const PayButtons = ({ analyticsEvent = false }) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 2000
        },
        requestPayerName: true,
        requestPayerEmail: true
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          analyticsEvent && analytics.logEvent('web_subs_applepay_see')
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe])

  const onClick = (type) => {
    if (type === 'applePay') {
      analytics.logEvent('web_subs_applepay_click', `price ${process.env.REACT_APP_PAYMENT}`)
      dispatch(setTypePopup(TYPE_PAYPAL_CONNECT))
      dispatch(setShowPopup(true))
    }
  }

  return (
    <div className='payButtons'>
      <PayPal />
      {paymentRequest
        ? <Button
            onClick={onClick}
            text='Buy with'
            icon='applePay'
          />
        : null}
    </div>
  )
}

export default PayButtons
