import { useState, useEffect } from 'react'

export default function useRandomInteger (min, max) {
  const [rand, setRand] = useState()

  useEffect(() => {
    const getRandomIntInclusive = (min, max) => {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
    return setRand(getRandomIntInclusive(min, max))
  }, [max, min])

  return rand
}
