import Picture1 from '../assets/image/culture/Picture-1.jpg'
import Picture2 from '../assets/image/culture/Picture-2.jpg'
import Picture3 from '../assets/image/culture/Picture-3.jpg'
import Picture4 from '../assets/image/culture/Picture-4.jpg'
import Picture6 from '../assets/image/culture/Picture-6.jpg'
import Picture7 from '../assets/image/culture/Picture-7.jpg'
import Picture8 from '../assets/image/culture/Picture-8.jpg'
import Picture9 from '../assets/image/culture/Picture-9.jpg'
import Picture10 from '../assets/image/culture/Picture-10.jpg'

const cultures = [
  {
    img: Picture1,
    text: 'Our co-founders taking a break after a long day in Los Angeles.'
  },
  {
    img: Picture2,
    text: 'Dmitry, our iOS lead, enjoying some heavy-lifting in Pisa.'
  },
  {
    img: Picture3,
    text: 'Our developers — Alexey, Vlad and Igor having fun at an Arcade Fire concert.'
  },
  {
    img: Picture4,
    text: 'Sergey, our CTO, keeping himself hydrated in Singapore.'
  },
  {
    img: Picture6,
    text: 'Marina, our Product Designer found love in Iceland.'
  },
  {
    img: Picture7,
    text: 'Sasha, our Internal Communications manager, showing her DJ talent at a party.'
  },
  {
    img: Picture8,
    text: 'Alla, our Designer, dancing under a plane with her sister in Sheremetyevo.'
  },
  {
    img: Picture9,
    text: 'Andrea, our accountant, celebrating her favorite holiday, Halloween.'
  },
  {
    img: Picture10,
    text: 'Vlad, our Customer Support Agent, volunteering in an Ugandan orphanage community.'
  }
]

export default cultures
