import useQuery from './useQuery'

export const useFlightReviewQuery = () => {
  const query = useQuery()
  const userCode = query.get('user_code')
  const carrier = query.get('carrier')?.toUpperCase()
  const number = query.get('number')
  const origin = query.get('origin')
  const destination = query.get('destination')
  const departureDateUtc = query.get('departure_date_utc')
  const userLocale = navigator.language || navigator.userLanguage

  function formatDateFromTimestamp (unixTimestamp: number) {
    const date = new Date(unixTimestamp * 1000) // Преобразование в миллисекунды

    const day = ('0' + date.getDate()).slice(-2) // Добавление ведущего нуля
    const month = ('0' + (date.getMonth() + 1)).slice(-2) // Месяцы начинаются с 0
    const year = date.getFullYear()

    // Определение локали пользователя

    // Выбор формата даты в зависимости от локали
    if (userLocale.startsWith('en-US')) {
      return `${month}.${day}.${year}` // Форматирование даты в mm.dd.yyyy для США
    } else {
      return `${day}.${month}.${year}` // Форматирование даты в dd.mm.yyyy для других стран
    }
  }

  const info = { userCode, carrier, flight: `${carrier} ${number}`, date: `${formatDateFromTimestamp(departureDateUtc)}`, segments: `${origin} → ${destination}`, userLocale: userLocale }

  return info
}
