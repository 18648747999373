/* eslint-disable no-undef */
import firebase from 'firebase/app'

export const logEvent = (name, payload) => {
  firebase.analytics().logEvent(name, payload)
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('LogEvent', name, payload)
  }
}

export const initAnalytics = () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyDvTwxPUQk8k4CI4w4zPSg9-FwRa2slcyo',
    authDomain: 'iappintheair.firebaseapp.com',
    databaseURL: 'https://iappintheair.firebaseio.com',
    projectId: 'iappintheair',
    storageBucket: 'iappintheair.appspot.com',
    messagingSenderId: '1003804154726',
    appId: '1:1003804154726:web:5c777d20cffee167f306a7',
    measurementId: 'G-KHHQYS50VC'
  })

  firebase.analytics()
}

export default {
  logEvent
}
