import React from 'react'
import analytics from '../../analytics'

const TypePrivacyPolicy = () => {
  return (
    <>
      <div className='popup__head'>
        Privacy Policy
      </div>
      <div className='popup__policy'>
        Please read these Terms and Conditions (T&Cs) before subscribing. When you subscribe to the program (the
        “Program”), you agree to accept these T&Cs.
        <br />
        <br />
        The Program is provided by App in the Air, Inc. (“us” or “we”). Please be aware that the T&Cs for the Program
        are different from the terms and conditions for travel bookings or for general use of our App. Those terms and
        conditions will continue to apply to you. Please see
        our
        &nbsp;
        <a
          href='/privacy-policy'
          target='blank'
          onClick={() => { analytics.logEvent('web_subs_tc_link_policy_open') }}
        >
          Privacy Policy
        </a>
        &nbsp;
        to understand how we collect and process
        your personal information.
        <br />
        <br />
        We reserve the right to modify/update/remove/extend the list of available benefits and these T&Cs at any time
        and remove participants from the Program at any time  (for any or no reason), including but not limited to, in
        the case of non-compliance with these T&Cs. Updated versions of the T&Cs are effective immediately on posting.
        <br />
        <br />
        PAYMENT DETAILS ENTERED MUST BELONG TO YOU. BY ENTERING PAYMENT DATA, YOU DECLARE THAT YOU HAVE THE RIGHT TO USE
        THEM AND ACCEPT SOLE RESPONSIBILITY FOR DOING SO.
        <br />
        <br />
        The Program is exclusively for private and personal use for individuals over 18 years old. If we detect
        commercial use, misuse, or fraudulent third-party use, we may at any moment and without prior notice to (i)
        verify use of the Program, (ii) deny account access, (iii) terminate your subscription, and (iv) demand a refund
        of unduly acquired advantages.
        <br />
        <br />
        By subscribing, you agree to use the Program in compliance with these T&Cs as well as all applicable laws, and
        to not use the Program in an abusive or fraudulent way. You cannot assign the benefit of the Program to a third
        party, use it in a commercial way, or use the identity of another user or account which does not belong to you.
        These T&Cs are governed by and interpreted in accordance with the laws of the State of California without regard
        to its conflict of laws provisions. The exclusive jurisdiction and venue of any action with respect to the
        subject matter of these T&Cs will be the U.S. federal courts and state courts located in Santa Clara,
        California, and each of the parties hereto waives any objection to jurisdiction and venue in such courts.
        However, we reserve the right to seek injunction relief in any jurisdiction when we deem it necessary.
      </div>
    </>
  )
}

export default TypePrivacyPolicy
