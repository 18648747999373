import { Link } from 'react-router-dom'
import './style.sass'
import info from '../../../assets/image/icons/shutdown-info.svg'
import infoPhone from '../../../assets/image/icons/shutdown-info-phone.svg'

const ShutdownBanner = () => {
  return (
    <Link to='/shutdown' className='shutdown__link-container'>
      <div className='shutdown__banner-container'>
        <picture>
          <source media='screen and (max-width: 640px)' srcset={infoPhone} />
          <source media='screen and (min-width: 641px)' srcset={info} />
          <img className='shutdown__img' src={info} alt='App in the Air is closing soon' />
        </picture>
        <p className='shutdown__banner-text'>App in the Air is closing soon</p>
        <div className='shutdown__banner-link'>Learn more</div>
      </div>
    </Link>
  )
}

export default ShutdownBanner
