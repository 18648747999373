import React, { useState } from 'react'
import AppUrls from '../../data/appsUrl'
import Api from '../../api'
import { logEvent } from '../../analytics'
import { useInViewport } from '../../hook/useInViewport'

const TypeCommunication = ({ type, answerType, setHide }) => {
  const api = new Api()
  const [email, setEmail] = useState('')

  const googlePlayRef = useInViewport(() => logEvent('aita_cp_store_main_storeButton_see', 'Google Play'))
  const appStoreRef = useInViewport(() => logEvent('aita_cp_store_main_storeButton_see', 'App Store'))
  const galaxyStoreRef = useInViewport(() => logEvent('aita_cp_store_main_storeButton_see', 'Galaxy Store'))

  const handleClick = (name) => () => {
    logEvent('aita_cp_store_main_storeButton_tap', name)
  }

  switch (answerType) {
    case 'button':
      return (
        <a
          href='mailto:support@appintheair.com'
          target='blank'
          rel='opener'
          className='answer__el'
          onClick={() => {
            setHide(true)
          }}
        >
          Write via email
        </a>
      )
    case 'mail':
      return (
        <div className='inputDiv'>
          <input
            type='email'
            className='input'
            placeholder='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button
            type='button'
            className='answer__el'
            onClick={() => {
              api.community(email, type)
                .then(res => {
                  setHide(true)
                })
            }}
          >
            Send
          </button>
        </div>
      )
    case 'app':
    default:
      return (
        <div className='apps'>
          <a href={AppUrls[0].url} target='blank' rel='opener' onClick={handleClick(AppUrls[0].name)} ref={appStoreRef}>{AppUrls[0].name}</a>,&nbsp;
          <a href={AppUrls[1].url} target='blank' rel='opener' onClick={handleClick(AppUrls[1].name)} ref={googlePlayRef}>{AppUrls[1].name}</a> and&nbsp;
          <a href={AppUrls[2].url} target='blank' rel='opener' onClick={handleClick(AppUrls[2].name)} ref={galaxyStoreRef}>{AppUrls[2].name}</a>
        </div>
      )
  }
}

export default TypeCommunication
