import { useEffect } from 'react'
import useQuery from '../../hook/useQuery'
import Api from '../../api'
import { setFlights, setStays, setSubscriptionInfo, setUserId } from './model'
import { useDispatch } from 'react-redux'
import subData from '../../data/subscription.json'
import analytics from '../../analytics'

export const CalculationController = () => {
  const api = new Api()
  const query = useQuery()
  const dispatch = useDispatch()
  let userIdP = null
  let flightsP = null
  let staysP = null
  useEffect(() => {
    getSubscriptionInfo()
    InitialFlightsAndStays()
  }, [])

  // Получаем начальный статус подписки у пользователя
  // активна\неактивна
  const getSubscriptionInfo = () => {
    const userId = query.get('offerId')
    if (userId) {
      userIdP = userId
      dispatch(setUserId(userId))
      api.subscriptionInfo(userId)
        .then(info => {
          dispatch(setSubscriptionInfo(info))
        })
    }
  }

  // забираем из query парамера flights и stays, проверяем на наличие в значения в списке
  const InitialFlightsAndStays = () => {
    let flights = query.get('flights')
    const stays = query.get('stays')
    if (flights) {
      flightsP = flights
      if (Object.keys(subData).indexOf(flights) >= 0) {
        dispatch(setFlights(flights))
      }
      // так так "+" в query параметре - это пробел - делаем доп проверку на его наличие
      if (Object.keys(subData).indexOf(flights + '+') >= 0) {
        dispatch(setFlights(flights + '+'))
        flights = flights + '+'
      }
      if (stays) {
        staysP = stays
        if (Object.keys(subData[flights]).indexOf(stays) >= 0) {
          dispatch(setStays(stays))
        }
        // так так "+" в query параметре - это пробел - делаем доп проверку на его наличие
        if (Object.keys(subData[flights]).indexOf(stays + '+') >= 0) {
          dispatch(setStays(stays + '+'))
        }
      }
    }
    analytics.logEvent('web_subs_open', `${userIdP};${flightsP};${staysP}`)
  }
}
