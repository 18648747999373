const TYPE = 'TYPE'
const STIPE_PAYMENT = 'STIPE_PAYMENT'
const PAYPAL_PAYMENT = 'PAYPAL_PAYMENT'
const PAYPAL_ACCOUNT = 'PAYPAL_ACCOUNT'
const SHOW_LOADER = 'SHOW_LOADER'
const defaultState = {
  type: null,
  stripePayment: null,
  paypalPayment: null,
  paypalAccount: null,
  showLoader: false
}
const PaymentModel = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE:
      return { ...state, type: action.payload }
    case STIPE_PAYMENT:
      return { ...state, stripePayment: action.payload }
    case PAYPAL_PAYMENT:
      return { ...state, paypalPayment: action.payload }
    case PAYPAL_ACCOUNT:
      return { ...state, paypalAccount: action.payload }
    case SHOW_LOADER:
      return { ...state, showLoader: action.payload }
    default:
      return state
  }
}

export default PaymentModel

export const setType = (type) => ({ type: TYPE, payload: type })
export const setStripePayment = (stripePayment) => ({ type: STIPE_PAYMENT, payload: stripePayment })
export const setPaypalPayment = (paypalPayment) => ({ type: PAYPAL_PAYMENT, payload: paypalPayment })
export const setPaypalAccount = (paypalAccount) => ({ type: PAYPAL_ACCOUNT, payload: paypalAccount })
export const setShowLoader = (showLoader) => ({ type: SHOW_LOADER, payload: showLoader })
