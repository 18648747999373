import React from 'react'
import './style.sass'
import img from '../../assets/image/book_flight_phone.png'
import useWindowSize from '../../hook/useWindowSize'

const BookFlight = () => {
  const windowSize = useWindowSize()
  return (
    <div className='bookFlight'>
      <div className='container'>
        <div className='bookFlight__desc'>
          <div className='head'>
            Book flights and hotels
          </div>
          <div className='text'>
            Save time and money. Our AI driven search tool, combined with a direct connection to airlines
            and hotels, finds you best flights that match your preferences. We’ll even find you the most
            eco-friendly flights.
          </div>
          {windowSize.desktop &&
            <div className='head head-yellow'>
              20,234,034 itineraries tracked
            </div>}
        </div>
        <img src={img} alt='' className='bookFlight__img' />

        {windowSize.tablet &&
          <div className='head head-yellow'>
            20,234,034 <br /> itineraries tracked
          </div>}
      </div>
    </div>
  )
}

export default BookFlight
