import React from 'react'
import './style.sass'
import { useDispatch, useSelector } from 'react-redux'
import iconClose from '../../assets/image/subscription/close.svg'
import {
  setShowPopup,
  TYPE_CHOOSE_PAY,
  TYPE_HAVE_SUBSCRIPTION,
  TYPE_PAYPAL_CONNECT,
  TYPE_PRIVACY_POLICY,
  TYPE_SUCCESS
} from './model'
import TypePayPalConnect from './_typePayPalConnect'
import TypeSuccess from './_typeSuccess'
import TypeHaveSubscription from './_typeHaveSubscription'
import TypeChoosePay from './_typeChoosePay'
import TypePrivacyPolicy from './_typePrivacyPolicy'

const PaymentPopup = () => {
  const dispatch = useDispatch()
  const showPopup = useSelector(state => state.popup.showPopup)
  const typePopup = useSelector(state => state.popup.typePopup)

  const closePopup = () => {
    dispatch(setShowPopup(false))
  }
  const renderPopupWrap = () => {
    switch (typePopup) {
      case TYPE_PAYPAL_CONNECT:
        return <TypePayPalConnect />
      case TYPE_SUCCESS:
        return <TypeSuccess closePopup={closePopup} />
      case TYPE_HAVE_SUBSCRIPTION:
        return <TypeHaveSubscription closePopup={closePopup} />
      case TYPE_CHOOSE_PAY:
        return <TypeChoosePay />
      case TYPE_PRIVACY_POLICY:
        return <TypePrivacyPolicy />
    }
  }
  if (showPopup) {
    return (
      <div className='paymentPopup'>
        <div
          className='paymentPopup__over'
          onClick={closePopup}
        />
        <div className='popup'>
          <button
            className='popup__close'
            onClick={closePopup}
          >
            <img src={iconClose} alt='' />
          </button>
          <div className='popup__wrap'>
            {renderPopupWrap()}
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default PaymentPopup
