import React from 'react'
import style from './style.module.sass'
import useCloseWebView from '../../hook/useCloseWebView'

export interface WarningI {
  message?: string,
}

export const Warning: React.FC<WarningI> = ({ message = 'Oops...Something went wrong. Please try again.' }) => {
  return (
    <div className={style.warning}>
      <p className={style.message}>{message}</p>
      <button className={style.button} onClick={() => useCloseWebView()}>Back to home</button>
    </div>
  )
}

export default Warning
