import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Item from './_item'
import './style.sass'

const privacySections = () => {
  const sections = useSelector((state) => state.privacy.sections)
  const [pageYOffset, setPageYOffset] = useState(0)

  useEffect(() => {
    const listenerPageYOffset = () => {
      setPageYOffset(window.scrollY)
    }
    window.addEventListener('scroll', listenerPageYOffset)
    return () => window.removeEventListener('scroll', listenerPageYOffset)
  }, [])

  return (
    <div className='privacyList'>
      <div className='container privacyContainer'>
        {sections.map((section) => (
          <Item
            key={section.id}
            title={section.attributes.title}
            text={section.attributes.paragraphs.data}
            pageYOffset={pageYOffset}
          />
        ))}
      </div>
    </div>
  )
}
export default privacySections
