const TYPE_POPUP = 'TYPE_POPUP'
const SHOW_POPUP = 'SHOW_POPUP'

export const TYPE_PAYPAL_CONNECT = 'TYPE_PAYPAL_CONNECT'
export const TYPE_CHOOSE_PAY = 'TYPE_CHOOSE_PAY'
export const TYPE_SUCCESS = 'TYPE_SUCCESS'
export const TYPE_HAVE_SUBSCRIPTION = 'TYPE_HAVE_SUBSCRIPTION'
export const TYPE_ERROR = 'TYPE_ERROR'
export const TYPE_PRIVACY_POLICY = 'TYPE_PRIVACY_POLICY'

const defaultState = {
  typePopup: TYPE_PAYPAL_CONNECT,
  showPopup: false
}

const PopupModel = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE_POPUP:
      return { ...state, typePopup: action.payload }
    case SHOW_POPUP:
      return { ...state, showPopup: action.payload }
    default:
      return state
  }
}

export default PopupModel

export const setTypePopup = (typePopup) => ({ type: TYPE_POPUP, payload: typePopup })
export const setShowPopup = (showPopup) => ({ type: SHOW_POPUP, payload: showPopup })
