
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Api from '../api'
import { setHeaderData, setSectionsData } from '../component/privacy/model'
import PrivacyFloat from '../component/privacyFloat'
import PrivacyHeader from '../component/privacyHeader'
import PrivacySections from '../component/privacySections'
import TitleH1 from '../component/titleH1'
import { Helmet } from 'react-helmet'

const PrivacyPage = () => {
  const api = new Api()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchToPrivacy = async () => {
      const { sectionsPrivacy } = await api.privacy()
      const { headerPrivacy } = await api.privacy()
      if (sectionsPrivacy && headerPrivacy) {
        dispatch(setSectionsData(sectionsPrivacy.data))
        dispatch(setHeaderData(headerPrivacy.data))
        setIsLoading(true)
        window.scrollTo({ top: 0 })
      }
    }
    fetchToPrivacy()
  }, [])

  return (
    <div className='page privacy'>
      <Helmet>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      {isLoading
        ? (
          <>
            <div className='privacyMain'>
              <PrivacyHeader />
              <PrivacySections />
            </div>
            <div className='privacyFloatContainer'>
              <PrivacyFloat />
            </div>
          </>)
        : (
          <div className='container'>
            <TitleH1 title='Loading...' addedClass='pageHeaderText' />
          </div>)}
    </div>
  )
}

export default PrivacyPage
