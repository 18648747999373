import React from 'react'
import KitCut from '../kitCut'
import './_smallPeople.sass'

const SmallPeople = ({ people }) => {
  return (
    <div className='people'>
      <img src={people.photo} alt='' className='people__photo' />
      <div className='people__desc'>
        <div className='desc__top'>
          <div className='people__name'>{people.name}</div>
          <div className='people__profession'>{people.profession}</div>
        </div>
        <div className='desc__button'>
          <div className='people__from'>{people.from}</div>
          <div className='people__info'>
            <KitCut review={people.info} initLimit={200} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmallPeople
