import React from 'react'
import './style.sass'

const Checkbox = ({ type, className, value, name, checked, setValue }) => {
  const changeCheckbox = (e) => {
    setValue(e.target.name, e.target.value)
  }
  return (
    <div className='checkbox'>
      <input
        id={name + value}
        name={name}
        type={type}
        value={value}
        checked={checked}
        onChange={changeCheckbox}
      />
      <label
        htmlFor={name + value}
        className={className}
      >
        {value.replace('-', ' - ').replace('+', ' +')}
      </label>
    </div>
  )
}

export default Checkbox
