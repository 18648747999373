import style from './style.module.sass'

export const DownloadData = () => {
  return (
    <section className={style.container}>
      <h2 className={style.title}>How To Export your Data</h2>
      <p className={style.paragraph}>Your App in the Air data will no longer be available after October 19, 2024. After that time all data may be retained or deleted in accordance with our privacy policy. Please see below for step by step instructions on how to export your account data.
      </p>
      <div className={style.block}>
        <ol className={style.list}>
          <li className={style.elem}>
            1. Launch the App in the Air app on your mobile device
          </li>
          <li className={style.elem}>
            2. Tap on the Profile tab, located at the bottom right of your screen
          </li>
          <li className={style.elem}>
            3. In your profile, tap the Settings icon in the top right corner of the screen
          </li>
          <li className={style.elem}>
            4. Scroll down through the settings until you reach the Other section at the very bottom of the page
          </li>
          <li className={style.elem}>
            5. Tap Export Data and specify the email you would like to receive a .txt file of your App in the Air Data
          </li>
        </ol>
      </div>
    </section>
  )
}
