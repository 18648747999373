import React from 'react'
import './style.sass'
import partner1 from '../../assets/image/sustainability/partners1.png'
import partner2 from '../../assets/image/sustainability/partners2.png'

const Partners = () => {
  return (
    <div className='partners'>
      <div className='partners__header'>
        Partners
      </div>
      <div className='partners__flex'>
        <div className='partner'>
          <div className='partner__logo'>
            <img src={partner1} alt='' />
          </div>
          <div className='partner__text'>
            <a href='https://onetreeplanted.org/' target='blank'>One Tree Planted</a> is a non-profit with a focus on
            global reforestation. As an environmental charity, they are dedicated to making it easier to give back to
            the environment, create a healthier climate, protect biodiversity and help reforestation efforts around the
            world.
          </div>
        </div>
        <div className='partner'>
          <div className='partner__logo'>
            <img src={partner2} alt='' />
          </div>
          <div className='partner__text'>
            <a href='https://xpansiv.com/cbl/' target='blank'>CBL Markets</a> provides access to the world's energy and environmental commodity
            markets, facilitating the secure and seamless trading of commodities like carbon, renewable energy, water,
            and gas.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
