import React from 'react'
import { useSelector } from 'react-redux'

const Button = ({ text, icon, color, onClick }) => {
  const showLoader = useSelector(state => state.payment.showLoader)
  return (
    <button
      className={'button ' + (!showLoader ? icon + ' ' + color : 'disable')}
      onClick={() => typeof onClick === 'function' ? onClick(icon) : ''}
    >
      {showLoader
        ? <svg className='button__loader' width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M17 9a8 8 0 11-8-8' stroke='#000' strokeWidth='2' strokeLinecap='round' /></svg>
        : text}
    </button>
  )
}

export default Button
