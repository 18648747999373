import React from 'react'
import './style.sass'
import analytics from '../../analytics'
import PayButtons from '../payButtons'
import { useDispatch, useSelector } from 'react-redux'
import { setShowPopup, setTypePopup, TYPE_PRIVACY_POLICY } from '../paymentPopup/model'

const SubscriptionPays = () => {
  const subscriptionInfo = useSelector(state => state.calculation.subscriptionInfo)
  const dispatch = useDispatch()

  const onClick = () => {
    analytics.logEvent('web_subs_tc_open')
    dispatch(setTypePopup(TYPE_PRIVACY_POLICY))
    dispatch(setShowPopup(true))
  }

  return (
    <div className='pays'>
      {(subscriptionInfo === null || subscriptionInfo.isActivated === false) &&
        <>
          <div className='subHead'>
            The more you travel, the greater the returns. So what are you waiting for?
          </div>
          <div className='head'>
            Become a member today!
          </div>
          <PayButtons />
        </>}
      <div className='star'>
        Find membership rules <br />
        and restrictions <span onClick={onClick}>here</span>
      </div>
    </div>
  )
}

export default SubscriptionPays
