const ItemHeader = ({ setIsOpen, title }) => {
  return (
    <div className='contentOpener__header' onClick={() => setIsOpen(prev => !prev)}>
      <div className='contentOpener__title'>
        {title}
      </div>
      <svg className='contentOpener__plus' width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M11.707 6.00009L10.2928 7.41431L5.99992 3.12141L1.70703 7.41431L0.292818 6.00009L5.99992 0.292987L11.707 6.00009Z' fill='#F8F8F8' />
      </svg>
    </div>
  )
}

export default ItemHeader
