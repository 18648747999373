import React, { useState } from 'react'
import './_questions.sass'
import questions from '../../data/questions'
import Question from './_question'
import AfterQuestion from './_afterQuestion'
import email from '../../assets/image/questions/email.svg'
import welcome from '../../assets/image/questions/welcome.svg'
import { useCookies } from 'react-cookie'

const Questions = () => {
  const [cookies, setCookie] = useCookies([])
  // показывать блок обратной связи
  const [showCommunication, setShowCommunication] = useState(true)
  //  сбор ответов по карточкам
  const [answers, setAnswers] = useState([])
  // карточка после опросника
  const [answer, setAnswer] = useState({
    text: 'Hooray! You can help us with many things. Thank you!',
    subText: 'Please leave your email address and we will write to you. No spam.',
    answerType: 'mail',
    type: ''
  })
  const newAnswer = (newAnswer) => {
    // собираем ответы
    answers.unshift(newAnswer)
    setAnswers(answers)
    // если все карточки прошли
    if (answers.length === questions.length) {
      // количество ответов "да"
      const yesCount = answers.filter(answer => answer === 'yes').length
      const yesPosition = []
      // ищем все "да"
      let idx = answers.indexOf('yes')
      while (idx !== -1) {
        yesPosition.push(idx)
        idx = answers.indexOf('yes', idx + 1)
      }
      // если все ответы "нет", скрываем блок с обратной связью
      if (yesCount === 0) {
        setShowCommunication(false)
      } else if (yesCount === 1) {
        // если "да" один, меняем карточку по типу
        setAnswer(questions[yesPosition[0]].answer)
      } else {
        // собираем все типы для "да"
        const type = yesPosition.map(position => {
          return questions[position].answer.type
        })
        // остовляем дефолтную карточку и типы подставляем
        setAnswer({
          ...answer,
          // удаляем undefined и пишем собраные типы
          type: type.filter(y => y).toString()
        })
      }
      // Ставим куку о прохождении.
      setCookie('quizDone', true, { path: '/' })
    }

    setTimeout(() => {
      const questionsDiv = document.getElementsByClassName('question-show')
      if (questionsDiv.length) {
        questionsDiv[questionsDiv.length - 1].style.transform = 'rotate(0deg)'
      }
    })
  }

  // Опросник
  const _renderQuiz = () => {
    return (
      questions.map((question, index) => {
        return <Question key={'question' + index} question={question} answers={answers} newAnswer={newAnswer} />
      })
    )
  }
  // карточка после прохождения опросника, если есть хоть один "да"
  const _renderCommunication = () => {
    if (showCommunication) {
      return (
        <AfterQuestion
          answer={answer}
          cardColor='#00D89C'
          cardImage={email}
        />
      )
    } else {
      return <></>
    }
  }
  return (
  // Так как карточки накладываем друг на друга, рисуем их в обратном порядке
    <div className='questions'>
      {/* финальная карточка ДОБРО ПОЖАЛОВАТЬ */}
      <AfterQuestion
        answer={{
          text: 'Welcome to the frequent flyers club!',
          answerType: 'app'
        }}
        cardColor='#70C6D8'
        cardImage={welcome}
      />
      {!cookies.quizDone &&
        <>
          {_renderCommunication()}
          {_renderQuiz()}
        </>}
    </div>
  )
}
export default Questions
