import React from 'react'
import PayButtons from '../payButtons'

const Total = () => {
  return (
    <div className='total'>
      <div className='head'>
        Become a member today!
      </div>
      <div className='price'>
        <div className='price-before'>
          <span className='discount'>50 $</span>
          <span className='percent'>-{process.env.REACT_APP_PAYMENT_DISCOUNT}%</span>
        </div>
        <div className='price-after'>
          <span className='currency'>$</span>
          <span className='sum'>{process.env.REACT_APP_PAYMENT}</span>
          <span className='fee'>
            One time <br /> membership fee
          </span>
        </div>

      </div>
      <PayButtons analyticsEvent />
    </div>
  )
}

export default Total
