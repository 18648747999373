import React from 'react'
import KitCut from '../kitCut'
import './_review.sass'

const Review = ({ review }) => {
  return (
    <div className='review'>
      <img src={review.map} alt='' className='map' />
      <div className='desc'>
        <div className='user'>
          <img src={review.avatar} alt='' className='avatar' />
          <div className='info'>
            <div className='name'>{review.name}</div>
            <div className='profession'>{review.profession}</div>
          </div>
        </div>
        <KitCut review={review.reviews} />
      </div>
    </div>
  )
}

export default Review
