import React from 'react'
import './style.sass'

const Copyright = () => {
  return (
    <div className='copyright'>
      <div className='container'>
        ©{new Date().getFullYear()} All right reserved.
      </div>
    </div>
  )
}

export default Copyright
