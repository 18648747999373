import q1 from '../assets/image/questions/1.svg'
import q2 from '../assets/image/questions/2.svg'
import q3 from '../assets/image/questions/3.svg'
import q4 from '../assets/image/questions/4.svg'
import q5 from '../assets/image/questions/5.svg'

const questions = [
  {
    id: 1,
    question: 'Have you noticed any errors in the app?',
    answerColor: '#65BFD2',
    cardColor: '#EDC968',
    image: q1,
    answer: {
      text: 'Hooray! You can make the app better and report bugs to us.',
      subText: 'Write to us and tell us about the bug',
      answerType: 'button',
      type: 'bug-report'
    }
  },
  {
    id: 2,
    question: 'Is your native language other than English?',
    answerColor: '#FFDF8A',
    cardColor: '#FF96B4',
    image: q2,
    answer: {
      text: 'Hooray! You can help us translate the application.',
      subText: 'Please leave your email address and we will write to you. No spam.',
      answerType: 'mail',
      type: 'translate'
    }
  },
  {
    id: 3,
    question: 'Do you want to tell your travel story on our blog?',
    answerColor: '#EDC968',
    cardColor: '#A77CFF',
    image: q3,
    answer: {
      text: 'Hooray! You can become the hero of the story in our blog.',
      subText: 'Please leave your email address and we will write to you. No spam.',
      answerType: 'mail',
      type: 'blog-story'
    }
  },
  {
    id: 4,
    question: 'Have you noticed that our FAQ lacks important information and you can add to it?',
    answerColor: '#00D291',
    cardColor: '#409577',
    image: q4,
    answer: {
      text: 'Hooray! You can update our FAQ with new information.',
      subText: 'Please leave your email address and we will write to you. No spam.',
      answerType: 'mail',
      type: 'faq-update'
    }
  },
  {
    id: 5,
    question: 'Do you want to review your past flights or share travel hacks?',
    answerColor: '#FF96B4',
    cardColor: '#B9D70A',
    image: q5,
    answer: {
      text: 'Hooray! You can share your experience in the app.',
      subText: 'Write a review after the end of the flight or through the Tips section of the airport information in the app',
      answerType: 'app'
    }
  }
]

export default questions.sort((a, b) => {
  return (a.id > b.id) ? -1 : 1
})
