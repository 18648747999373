import React from 'react'
import './style.sass'
import AppUrls from '../../data/appsUrl'

const ChangeTheWorldTogether = () => {
  return (
    <div className='changeTheWorldTogether'>
      <div className='changeTheWorldTogether__header'>
        Let’s change the world <br /> together
      </div>
      <div className='changeTheWorldTogether__link'>
        Download free on the <a href={AppUrls[0].url} target='blank' rel='opener' className='link__el'>{AppUrls[0].name}</a>, <a href={AppUrls[1].url} target='blank' rel='opener' className='link__el'>{AppUrls[1].name}</a><br /> or <a href={AppUrls[2].url} target='blank' rel='opener' className='link__el'>{AppUrls[2].name}</a>
      </div>
    </div>
  )
}

export default ChangeTheWorldTogether
