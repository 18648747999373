import React from 'react'
import './style.sass'
import clock1 from '../../assets/image/clock/clock1.png'
import clock2 from '../../assets/image/clock/clock2.png'

const WhyLove = () => {
  return (
    <div className='love-air'>
      <div className='container'>
        <div className='love-air_text'>
          <div className='love-air_text_title'>
            Why do people love App in The Air?
          </div>
          <div className='love-air_text_animate'>
            <span className='second_animate'>Terminal.&nbsp;Gate.&nbsp;Tail<br />number.
              <span className='love-air_animated love-air_cursor love-air_animated_baggage'>Baggage rules.</span>
              <span className='love-air_animated love-air_cursor love-air_animated_auto'>Auto check-in.</span>
              <span className='love-air_animated love-air_cursor love-air_animated_status'>Flight status alerts.</span>
              <span className='love-air_animated love-air_cursor love-air_animated_reviews'>Flights reviews.</span>
              <span className='love-air_animated love-air_cursor love-air_animated_family'>Subscribe your family.</span>
            </span>
          </div>
        </div>
        <div className='love-air_img'>
          <img className='love-air_img_first' src={clock1} alt='' />
          <img className='love-air_img_second' src={clock2} alt='' />
        </div>
      </div>
    </div>
  )
}

export default WhyLove
