import React from 'react'
import Button from '../button'
import './style.sass'
import useWindowSize from '../../hook/useWindowSize'

const ForPeopleAndThePlanet = ({ setShowCO2 }) => {
  const windowSize = useWindowSize()
  return (
    <div className='forPeopleAndThePlanet'>
      <div className='forPeopleAndThePlanet__header'>
        For people <br />
        and the planet
      </div>
      <div className='forPeopleAndThePlanet__description'>
        Travel fuels life for our community. We also know that it can have long term implications to the planet as well.
        <span> At App in the Air, we believe that the travel industry can be a driving force of positive social and
          environmental change
        </span>, which is why we’ve launched several initiatives to help mitigate the aviation industry’s
        carbon footprint.
      </div>
      {!windowSize.desktop &&
        <Button
          className='btn__green'
          onClick={() => setShowCO2(true)}
          text='CO2 footprint calculator'
        />}
    </div>
  )
}

export default ForPeopleAndThePlanet
