import style from './style.module.sass'

export const Thanks = () => {
  return (
    <section className={style.container}>
      <h2 className={style.title}>Thank You For Your Loyalty and Support</h2>
      <p className={style.paragraph}>
        It is with a heavy heart that we inform our community of aviation enthusiasts, frequent fliers and globe trotters that our journey together is coming to an end. Despite our best efforts to navigate the ever-changing and challenging business environment, we have made the difficult decision to discontinue our services. Beginning on <strong>September 19th, 2024</strong> our app will cease to be available on the Apple App Store, Google Play and Samsung Galaxy Store. Customers will be able to access and export their data through <strong>October 19th 2024</strong>.
      </p>
      <p className={style.paragraph}>
        We want to express our deepest gratitude to you, our community of loyal customers and fans, for your unwavering support throughout our decade plus journey together. Your passion for aviation and your trust in our app have meant the world to us. It has been an honor to serve you, and we are incredibly proud of what we were able to achieve together. Additional details related to some of the questions you might have are available below.
      </p>
      <p className={style.paragraph}>We also want to take this moment to recognize and thank our co-founders and dedicated development team, whose hard work, sacrifices and commitment made our 20,000+ app store reviews, multiple industry awards and coverage across top publications around the world possible.Their contributions have been invaluable in delivering the best experience to our users.
      </p>
      <p className={style.paragraph}>We hope the memories created and the connections made will continue to inspire your love for aviation.</p>
      <p className={style.paragraph}>Thank you once again for being part of our community. If you have any questions or need further assistance during this transition, please don’t hesitate to contact our support team at <a className={style.link} href='mailto:support@appintheair.com'>support@appintheair.com</a> prior to October 19th.</p>
      <p className={style.paragraph}>Sincerely the App in the Air Team</p>
    </section>
  )
}
