import { useSelector } from 'react-redux'
import corretctSortPrivacy from '../../hook/corretctSortPrivacy'
import PrivacyCards from '../privacyCards'
import Text from '../text'
import TitleH1 from '../titleH1'
import PrivacySelectMobile from './_selectMobile'
import './style.sass'
import moment from 'moment'

const PrivacyHeader = () => {
  const headerData = useSelector((state) => state.privacy.header.attributes)

  return (
    <div className='container privacyContainer'>
      <TitleH1 title={headerData.title} />
      <Text text={`Last updated ${moment(headerData.lastUpdated).format('DD MMM YYYY')}`} />
      {headerData.paragraphs?.data.sort(corretctSortPrivacy).map((paragraph) => (
        <Text key={paragraph.id} text={paragraph.attributes.text} />
      ))}
      <PrivacySelectMobile />
      <PrivacyCards />
    </div>
  )
}
export default PrivacyHeader
