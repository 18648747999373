import React from 'react'
import PageHeaderText from '../component/pageHeaderText'
import Curtain from '../component/curtain'
import TeamsSlider from '../component/teamsSlider'
import Vacancies from '../component/vacancies'
import MiniStory from '../component/miniStoory'
import cultures from '../data/culture'
import { Helmet } from 'react-helmet'
const TeamPage = () => {
  return (
    <div className='page page-team'>
      <Helmet>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <div className='container'>
        <PageHeaderText text='We’re here to make your travel journey easier' />
      </div>
      <Curtain />
      <TeamsSlider />
      <Vacancies />
      <MiniStory
        head='Our culture'
        text={
                "Our offices are in Seattle and Vienna. Even though we're miles apart, we communicate and collaborate " +
                "every day and we're all united by the love of travel. We're not afraid of trying new and innovative " +
                "things - especially if it makes our user's lives easier. We invite you to explore your next " +
                'adventure with us.'
}
        miniStory={cultures}
      />
    </div>
  )
}

export default TeamPage
