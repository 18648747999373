import React from 'react'
import './style.sass'
import useWindowSize from '../../hook/useWindowSize'
import { CalculationController } from './controller'

import Info from './_info'
import Calc from './_calc'
import List from './_list'
import HaveSubscription from '../haveSubscription/inde'
import Total from './_total'
import { useSelector } from 'react-redux'

const Calculation = () => {
  CalculationController()
  const windowSize = useWindowSize()
  const subscriptionInfo = useSelector(state => state.calculation.subscriptionInfo)
  return (
    <div className='calculation'>
      <div className='container'>
        <div className='wrapper'>
          <Info />
          <Calc />
        </div>
        {windowSize.phone &&
          <List />}
        {subscriptionInfo !== null && subscriptionInfo.isActivated === true
          ? <HaveSubscription />
          : <Total />}
      </div>
    </div>
  )
}

export default Calculation
