import './style.sass'

const Item = ({ title, text, img, alt }) => {
  return (
    <div className='privacyCards__item'>
      <img src={img} className='privacyCards__item-img' alt={alt} />
      <p className='privacyCards__item-title'>{title}</p>
      <p className='privacyCards__item-text'>{text}</p>
    </div>
  )
}
export default Item
