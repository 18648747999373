import React from 'react'
import './style.sass'

const CO2Calculation = ({ showCO2, setShowCO2 }) => {
  return (
    <div className={'CO2Calculation ' + (showCO2 && 'CO2Calculation__show')}>
      <div
        className='CO2Calculation__overflow'
        onClick={() => setShowCO2(false)}
      />
      <div className='CO2Calculation__iframe'>
        <div
          className='CO2Calculation__close'
          onClick={() => setShowCO2(false)}
        />
        <iframe
          src='https://apps.aita.travel/co2/#/'
        />
      </div>
    </div>
  )
}

export default CO2Calculation
