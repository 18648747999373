import React from 'react'
import style from './style.module.sass'
import cn from 'classnames'
import { AnswerProps } from '../../types'
import info from '../../assets/image/icons/info.svg'
import { ThreeDots } from 'react-loader-spinner'

export const Answer: React.FC<AnswerProps> = ({
  title,
  children,
  onNextClick,
  onBackClick,
  disabledNext,
  form,
  setModalOpen
}) => {
  return (
    <div className={style.bottom}>
      <div className={style['title-container']}>
        <h5 className={style.title}>{title}</h5>
        {title === 'Select up to four options'
          ? <button className={style['info-button']}><img src={info} onClick={() => setModalOpen((prev: boolean) => !prev)} /></button>
          : null}
      </div>
      <div className={style.contents}>{children}</div>
      <footer className={style.footer}>
        <button
          disabled={!onBackClick}
          onClick={onBackClick}
          className={cn(style.back, style.button)}
        >
          Back
        </button>
        <button disabled={disabledNext} form='review' onClick={onNextClick} className={cn(style.next, style.button)}>
          {form && disabledNext
            ? <ThreeDots
                visible
                height='40'
                width='40'
                color='#8F8F8F'
                radius='9'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClass=''
              />
            : form ? 'Submit' : 'Next'}
        </button>
      </footer>
    </div>
  )
}
