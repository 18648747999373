import React, { useState } from 'react'

import { FlightReview } from '../../views'
import style from './style.module.sass'
import Warning from '../../component/warning'
import Modal from '../../component/modal'

export const FlightsReviewContainer = () => {
  const [error, setError] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <div className={style['flights-review-wrap']}>
      {error && !error.message.includes('Review already exists') && !error.message.includes('Cannot read properties of undefined')
        ? <Warning
            message={error?.message}
          />
        : null}
      {modalOpen ? <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} /> : null}
      <FlightReview error={error} setError={setError} setModalOpen={setModalOpen} />
    </div>
  )
}
export default FlightsReviewContainer
