import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import replaceSpacesWithUnderscores from '../../../hook/replaceSpacesWithUnderscores'
import { setCurrentSection } from '../../privacy/model'
import ItemBody from './_itemBody'
import ItemHeader from './_itemHeader'
import '../style.sass'

const Item = ({ title, text, pageYOffset }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState()
  const currentSection = useSelector((state) => state.privacy.currentSection)
  const element = useRef()
  const offsetTop = element.current?.getBoundingClientRect().top
  const offsetForCurrentElement = 210
  const sections = useSelector((state) => state.privacy.sections)

  const changeCurrentElement = (id) => {
    dispatch(setCurrentSection(id))
  }

  useEffect(() => {
    const underscoresTitle = replaceSpacesWithUnderscores(title)
    if (
      offsetTop <= offsetForCurrentElement &&
      offsetTop > 0 &&
      currentSection !== underscoresTitle
    ) {
      changeCurrentElement(underscoresTitle)
    }
  }, [pageYOffset])

  useEffect(() => {
    // Функция для скролла к элементу по id
    const scrollToElement = (id) => {
      const element = document.getElementById(id)
      if (element) {
        dispatch(setCurrentSection(id))
        setIsOpen(id)
        const offsetTop = element.getBoundingClientRect().top - 50
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      }
    }

    // Получаем id из URL
    const hash = window.location.hash.replace('#', '')
    if (hash && sections) {
      // Если hash существует и sections загружены, выполняем скролл к элементу с этим id
      scrollToElement(hash)
    }
  }, [sections])

  return (
    <div
      className={`privacyList__item ${isOpen === replaceSpacesWithUnderscores(title) ? 'privacyList__item-open' : ''}`}
      id={replaceSpacesWithUnderscores(title)}
      ref={element}
    >
      <ItemHeader setIsOpen={setIsOpen} title={title} id={replaceSpacesWithUnderscores(title)} />
      <ItemBody text={text} />
    </div>
  )
}
export default Item
