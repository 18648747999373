import style from './style.module.sass'
import logo from '../../assets/image/review/logo_AITA-small.svg'
import title from '../../assets/image/review/Logo-title.svg'
import close from '../../assets/image/review/close.svg'
import cn from 'classnames'
import React from 'react'
import { ReactSVG } from 'react-svg'
import { QuestionProps } from '../../types'
import useCloseWebView from '../../hook/useCloseWebView'

export const Question: React.FC<QuestionProps> = ({
  question = '',
  info,
  checkedItems = 4,
  children,
  carrier,
  type
}) => {
  return (
    <div className={style.top}>
      <header className={style.header}>
        <ul className={style.pages}>
          <li
            className={cn(style.page, {
              [style['page-done']]: checkedItems === 1 || checkedItems > 1
            })}
          />
          <li
            className={cn(style.page, {
              [style['page-done']]: checkedItems === 2 || checkedItems > 2
            })}
          />
          <li
            className={cn(style.page, {
              [style['page-done']]: checkedItems === 3 || checkedItems > 3
            })}
          />
          <li
            className={cn(style.page, {
              [style['page-done']]: checkedItems === 4
            })}
          />
        </ul>
        <div className={style['logo-container']}>
          <div className={style['logo-container-img']}>
            <ReactSVG src={logo} className={style['logo-icon']} />
            <ReactSVG src={title} className={style['logo-icon']} />
          </div>
          <button
            onClick={() => useCloseWebView(type)}
          >
            <ReactSVG src={close} />
          </button>
        </div>
      </header>
      <div>{carrier &&
        <div className={style.carrierIcon}>
          <img alt='carrier' src={carrier} className={style.carrier} />
        </div>}
        <p className={style.question}> {question.split('').map((char, index) => {
          return (
            <span
              aria-hidden='true'
              key={index}
              style={{ 'animation-delay': (0.005 + index / 50) + 's' }}
            >
              {char}
            </span>
          )
        }
        )}
        </p>

        {children}
        {
                    !info
                      ? null
                      : (
                        <div className={style.info}>
                          <div className={style['info-top']}>
                            <span className={style.flight}>{info?.flight}</span>
                            <span>&#8226;</span>
                            <span className={style.date}>{info?.date}</span>
                          </div>
                          <div className={style['info-bottom']}>
                            <span className={style.segments}>{info?.segments}</span>
                          </div>
                        </div>)
                }
      </div>
    </div>
  )
}
