import React from 'react'
import './style.sass'
import PageHeaderText from '../pageHeaderText'

const TipsCount = () => {
  return (
    <div className='tipsCount'>
      <div className='container'>
        <PageHeaderText
          text='Over 500,000 tips added by travelers <br/> around the world.'
        />
        <div className='tipsCount__link'>
          <a href='https://links.appintheair.mobi/explore-tips' target='blank' rel='opener'>More tips in the app</a>
        </div>
      </div>
    </div>
  )
}

export default TipsCount
