import React from 'react'
import SubscriptionBanner from '../component/banners/subscription'
import Calculation from '../component/calculation'
import HowWorks from '../component/howWorks'
import Copyright from '../component/copyright'
import PaymentPopup from '../component/paymentPopup'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import 'firebase/analytics'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Helmet } from 'react-helmet'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
  apiVersion: process.env.REACT_APP_STRIPE_API_VERSION
})
const SubscriptionPage = () => {
  return (
    <div className='page page-subscription'>
      <Helmet>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <PayPalScriptProvider
        options={{ 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID }}
      >
        <Elements stripe={stripePromise}>
          <SubscriptionBanner />
          <Calculation />
          <HowWorks />
          <Copyright />
          <PaymentPopup />
        </Elements>
      </PayPalScriptProvider>
    </div>
  )
}

export default SubscriptionPage
