import React from 'react'
import wifi from '../../assets/image/review/amenities/wifi.svg'
import booking from '../../assets/image/review/amenities/booking.svg'
import departure from '../../assets/image/review/amenities/departure.svg'
import aircraft from '../../assets/image/review/amenities/aircraft.svg'
import arrival from '../../assets/image/review/amenities/arrival.svg'

import cleaning from '../../assets/image/review/amenities/cleaning.svg'
import seat from '../../assets/image/review/amenities/seat.svg'
import airlines from '../../assets/image/review/amenities/airlines.svg'
import food from '../../assets/image/review/amenities/food.svg'
import entertainment from '../../assets/image/review/amenities/entertainment.svg'
import other from '../../assets/image/review/amenities/more.svg'
import cn from 'classnames'
import { ReactSVG } from 'react-svg'
import style from './style.module.sass'
import { AmenityCheckboxProps } from '../../types'

const icons = {
  'Booking experience': booking,
  'Departure Experience': departure,
  Seat: seat,
  Cleanliness: cleaning,
  'Food & Beverage': food,
  'Cabin Crew Service': airlines,
  'Inflight Entertainment': entertainment,
  'Wi-Fi': wifi,
  Aircraft: aircraft,
  'Arrival Experience': arrival,
  Other: other
}

export const AmenityCheckbox: React.FC<AmenityCheckboxProps> = ({ title, handleChange, hasFour, isChecked }) => {
  return (
    <label
      key={title}
      tabIndex={1}
      className={cn(style.label, {
        [style.checked]: isChecked,
        [style.disabled]: !isChecked && hasFour,
        [style.width100]: title === 'Other'
      })}
    >
      <ReactSVG
        src={icons[title]}
        className={cn(style.img, {
          [style.checked]: isChecked,
          [style.disabled]: !isChecked && hasFour
        })}
      />
      <span className={style.title}>{title}</span>
      <input
        disabled={!isChecked && hasFour}
        type='checkbox'
        checked={isChecked}
        onChange={() => {
          handleChange(title)
        }}
      />
    </label>
  )
}
