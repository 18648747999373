import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MainPage from '../page/mainPage'
import TeamPage from '../page/teamPage'
import CommunityPage from '../page/communityPage'
import SubscriptionPage from '../page/subscriptionPage'
import SustainabilityPage from '../page/sustainabilityPage'
import Main from '../layouts/main'
import ScrollToTop from './scrollTop'
import PrivacyPage from '../page/privacyPage'
import DownloadPage from '../page/downloadPage'

import { FlightsReviewContainer as ReviewPage } from '../page/reviewPage/reviewPage.tsx'
import FinalPage from '../page/finalPage'
import ShutdownPage from '../page/shutdownPage'

const Navigation = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path='/subscription/:flight?/:stays?' component={SubscriptionPage} />
        <Route path='/download' exact component={DownloadPage} />
        <Route path='/flight_review/:flightStatusId' exact component={ReviewPage} />
        <Route path='/finalScreen/:status' exact component={FinalPage} />

        <Route>
          <Main>
            <Switch>
              <Route path='/sustainability' exact component={SustainabilityPage} />
              <Route path='/privacy-policy' exact component={PrivacyPage} />
              <Route path='/community' exact component={CommunityPage} />
              <Route path='/team' exact component={TeamPage} />
              <Route path='/shutdown' exact component={ShutdownPage} />
              <Route path='/*' exact component={MainPage} />
            </Switch>
          </Main>
        </Route>

      </Switch>
    </Router>
  )
}

export default Navigation
