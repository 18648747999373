import React from 'react'
import './style.sass'
import AppUrls from '../../data/appsUrl'
import { logEvent } from '../../analytics'
import { useInViewport } from '../../hook/useInViewport'

const CreateAcc = () => {
  const appStoreRef = useInViewport(() => logEvent('aita_mp_store_main_bottomTextStoreButton_see', 'App Store'))
  const googlePlayRef = useInViewport(() => logEvent('aita_mp_store_main_bottomTextStoreButton_see', 'Google Play'))
  const galaxyStoreRef = useInViewport(() => logEvent('aita_mp_store_main_bottomTextStoreButton_see', 'Galaxy Store'))
  const handleClick = (name) => () => {
    logEvent('aita_mp_store_main_bottomTextStoreButton_tap', name)
  }
  return (
    <div className='createAcc'>
      <div className='container'>
        <div className='head'>
          Create your account. <br />
          We do the rest.
        </div>
        <div className='text'>
          Let us help make your travel journey easier.
          <br />
          <br />
          Download on the &nbsp;
          <a href={AppUrls[0].url} target='blank' rel='opener' onClick={handleClick(AppUrls[0].name)} ref={appStoreRef}>{AppUrls[0].name}</a>,  &nbsp;
          <a href={AppUrls[1].url} target='blank' rel='opener' onClick={handleClick(AppUrls[1].name)} ref={googlePlayRef}>{AppUrls[1].name}</a> <br />
          or <a href={AppUrls[2].url} target='blank' rel='opener' onClick={handleClick(AppUrls[2].name)} ref={galaxyStoreRef}>{AppUrls[2].name}</a>
        </div>
      </div>
    </div>
  )
}

export default CreateAcc
