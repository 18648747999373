import React from 'react'
import Header from '../component/header'
import Footer from '../component/footer'

export default ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  )
}
