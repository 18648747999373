import React from 'react'
import './style.sass'
import img from '../../assets/image/curtain.png'
import PageSubHeaderText from '../pageSubHeaderText'

const Curtain = () => {
  return (
    <div className='curtain'>
      <div className='container'>
        <div className='curtain__desc'>
          <PageSubHeaderText text='Behind the curtain' />
          <div className='text'>
            App in the Air is made up of a team of builders, working together to change how the world experiences travel.
            <br />
            <br />
            When we started in 2012, we wanted to disrupt the way travelers tracked and managed their itineraries. Shifting to the present, we've gone from a simple flight tracker to building a community of nearly 6M users that rely on our app to help them navigate their travel plans.
            <br />
            <br />
            Our mission is to be your personal travel assistant. That means supporting your travel journey from planning to booking to managing your itinerary, all in one place. We help our users find the best solutions to book the perfect trip.
            <br />
            <br />
            It's your world. We're just helping you explore it.
          </div>
        </div>
        <img src={img} alt='' className='curtain__img' />
      </div>
    </div>
  )
}

export default Curtain
