import { Answer } from '../../component/answer'
import { Question } from '../../component/question'
import style from '../style.module.sass'
import { AmenityCheckbox } from '../../component/amenityCheckbox'
import React from 'react'
import { FlightsReviewViewState, amenities, PagesProps } from '../../types'
import { useFlightReviewQuery } from '../../hook/useFlightReviewQuery'
import _ from 'lodash'

export const FlightImprovements:React.FC<PagesProps> = ({ setCurrentPage, data, setData, error, setModalOpen }) => {
  const info = useFlightReviewQuery()
  const handleChange = (title: amenities) => {
    setData(prev => {
      if (prev.needToBeImproved.includes(title)) {
        return { ...prev, needToBeImproved: prev.needToBeImproved.filter((el: amenities) => el !== title) }
      } else {
        return { ...prev, needToBeImproved: [...prev.needToBeImproved, title] }
      }
    })
  }

  return (
    <div className={style.flightImprovements}>
      <Question
        info={info}
        question='What can be improved on your flight?'
        checkedItems={3}
        carrier={`https://storage.googleapis.com/iappintheair.appspot.com/Airlines/SquaredLogos/${info.carrier}.png`}
        type=''
      />
      <Answer
        title='Select up to four options'
        setModalOpen={setModalOpen}
        onBackClick={() => {
          setCurrentPage(FlightsReviewViewState.FlightAdvantages)
        }}
        onNextClick={() => {
          setCurrentPage(FlightsReviewViewState.FlightExperience)
        }}
      >
        <div className={style.checkboxes}>
          {_.map(_.filter(amenities, amenity =>
            amenity !== 'Other' && !data.liked.includes(amenity)), (amenity) =>
              <AmenityCheckbox
                isChecked={Boolean(data.needToBeImproved.find(el => el === amenity))}
                hasFour={data.needToBeImproved.length === 4}
                handleChange={handleChange}
                title={amenity}
              />
          )}
          <AmenityCheckbox
            isChecked={Boolean(data.needToBeImproved.find(el => el === amenities.Other))}
            hasFour={data.needToBeImproved.length === 4}
            handleChange={handleChange}
            title={amenities.Other}
          />
        </div>
      </Answer>
    </div>
  )
}
