export default function usePostMessage (event = '', data = '') {
  console.log('usePostMessage', event, data)
  try {
    window.webkit.messageHandlers[event].postMessage(data)
  } catch (error) {
    console.error(error)
  }

  try {
    AndroidInterface[event](data)
  } catch (error) {
    console.error(error)
  }
}
