import React from 'react'
import './style.sass'
import Teams from '../../data/teams'
import Slider from 'react-slick'
import TeamEl from './_teamEl'
import PageSubHeaderText from '../pageSubHeaderText'

const TeamsSlider = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true

        }
      }
    ]
  }

  const SLIDER_ROWS = 4
  const _renderSlideColumn = () => {
    const html = []
    for (let i = 0; i <= Teams.length; i++) {
      if (i % SLIDER_ROWS >= 1 && i % SLIDER_ROWS <= SLIDER_ROWS - 1) continue
      html.push(
        <div key={'teamsSlider__column' + i} className='teamsSlider__column'>
          {Teams.slice(i, i + SLIDER_ROWS).map((team, index) => {
            return (
              <TeamEl key={team.name + index} team={team} />
            )
          })}
        </div>
      )
    }
    return html
  }

  return (
    <div className='teamsSlider'>
      <div className='container'>
        <PageSubHeaderText text='Meet our crew' />
        <Slider {...settings}>
          {_renderSlideColumn()}
        </Slider>
      </div>
    </div>
  )
}

export default TeamsSlider
