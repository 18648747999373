import React, { useState } from 'react'
import './_question.sass'
import useRandomInteger from '../../hook/useRandomInteger'

const Question = ({ question, newAnswer }) => {
  const [hide, setHide] = useState(false)
  const [active, setActive] = useState('')
  const rotate = useRandomInteger(-5, 5)
  return (
    <div
      className={'question ' + (hide ? ' question-hide' : ' question-show')} style={{
        // При наличии ответа, юерем цвет и картинку из него, иначи задаем отдельно
        backgroundColor: question.cardColor,
        backgroundImage: `url(${question.image})`,
        // Крутим карточку рандомом
        transform: `rotate(${rotate}deg)`
      }}
    >
      <div className='question__wrp'>
        <div className='question__text'>
          {question.question}
        </div>
        <div className='question__answer'>
          <button
            type='button'
            className={'answer__el ' + (active === 'yes' && 'answer__el-active')}
            style={{
              backgroundColor: question.answerColor,
              borderColor: question.answerColor
            }}
            onClick={() => {
              newAnswer('yes')
              setHide(true)
              setActive('yes')
            }}
          >
            Yes
          </button>
          <button
            type='button'
            className={'answer__el ' + (active === 'no' && 'answer__el-active')}
            style={{
              backgroundColor: question.answerColor,
              borderColor: question.answerColor
            }}
            onClick={() => {
              newAnswer('no')
              setHide(true)
              setActive('no')
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question
