import appsUrl from '../data/appsUrl'
import _ from 'lodash'

const DownloadPage = () => {
  const iosDevice = [
    'iphone',
    'ipod',
    'ipad',
    'ios'
  ]
  const samsungDevice = [
    'samsung',
    ' sm-'
  ]
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.match(iosDevice.join('|'))) {
    window.location.href = _.find(appsUrl, { name: 'App Store' }).url
  } else if (userAgent.match(samsungDevice.join('|'))) {
    window.location.href = _.find(appsUrl, { name: 'Galaxy Store' }).url
  } else if (userAgent.match('android')) {
    window.location.href = _.find(appsUrl, { name: 'Google Play' }).url
  } else {
    window.location.href = 'https://appintheair.com/'
  }

  return ''
}

export default DownloadPage
