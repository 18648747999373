import './assets/style/main.sass'
import React from 'react'
import Navigation from './navigation'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import { store } from './reducers'
import { initAnalytics } from './analytics'

initAnalytics()

export default function App () {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <Navigation />
      </Provider>
    </CookiesProvider>
  )
}
