import { useSelector } from 'react-redux'
import Item from './_item'
import './style.sass'

const PrivacyCards = () => {
  const cards = useSelector((state) => state.privacy.header.attributes?.cards)

  return (
    <div className='privacyCards'>
      {cards?.map((card) => (
        <Item
          key={card.id}
          img={card.image.data.attributes.url}
          alt={card.image.data.attributes.name}
          title={card.title}
          text={card.description}
        />
      ))}
    </div>
  )
}

export default PrivacyCards
