import map1 from '../assets/image/reviews/map_1.png'
import map2 from '../assets/image/reviews/map_2.png'
import map3 from '../assets/image/reviews/map_3.png'
import map4 from '../assets/image/reviews/map_4.png'
import map5 from '../assets/image/reviews/map_5.png'
import map6 from '../assets/image/reviews/map_6.png'
import avatar1 from '../assets/image/reviews/avatar_1.png'
import avatar2 from '../assets/image/reviews/avatar_2.png'
import avatar3 from '../assets/image/reviews/avatar_3.png'
import avatar4 from '../assets/image/reviews/avatar_4.png'
import avatar5 from '../assets/image/reviews/avatar_5.png'
import avatar6 from '../assets/image/reviews/avatar_6.png'

const ReviewsData = [
  {
    map: map1,
    avatar: avatar1,
    name: 'Rasmus H.',
    profession: 'Principle Technologist',
    reviews: 'Having the seamless travel experience provided by App in the Air is critical for me with 100+ travel days. App in the Air is how I make life on the road manageable, and I can’t imagine travelling without this virtual assistant.'
  },
  {
    map: map2,
    avatar: avatar2,
    name: 'Mike D.',
    profession: 'Managing Partner',
    reviews: 'Does as it says. I’m having a lot of fun tracking my flights and all the other little details. Updated regulary. Has a bunch of features you can switch on or off. Especially useful if you travel a lot.'
  },
  {
    map: map3,
    avatar: avatar3,
    name: 'Polina M.',
    profession: 'Master of Performing Arts',
    reviews: 'App in the air is my favorite traveling app. It helps me to organize all my flights. I love getting app notifications about my upcoming travel. I always feel informed and it makes my life easier! Thank you for such an amazing app!'
  },
  {
    map: map4,
    avatar: avatar4,
    name: 'Lucas Dubois',
    profession: 'Managing Director',
    reviews: `I’m a HUGE fan of AITA. Lifetime subscriber for over two years now, I recommend this app to all my friends and family.
                  The interactive, playful and informative UI is awesome!
                  Keep it up!`
  },
  {
    map: map5,
    avatar: avatar5,
    name: 'Julián Ariel Battioli Di Menza',
    profession: 'Travel Blogger',
    reviews: 'I love it! App in the Air is that app you have no idea how useful it is till you use it for the very first time! I\'ve been using App in the Air for more than 5 years and it has become my favorite travel partner. It keeps me updated on every detail of my trip, and I can manage everything from one app: hotel, flights, car.... And no need to check airport screens! you will have everything in your phone. Now I cannot imagine a single trip without it!'
  },
  {
    map: map6,
    avatar: avatar6,
    name: 'Augusto Hardke Fernandes',
    profession: 'ATPL(A) Student at IFA Training',
    reviews: 'Definitely the best app to keep my flights under control from departure to arrival at destination. The app offers lots of useful and indispensable information about the flight. I’m not stressed anymore before take a flight. I receive instant notifications for every change and I keep tracking my flight even in the sky. Really helpful!'
  }
]

export default ReviewsData
