import React from 'react'
import { useSelector } from 'react-redux'
import PayPalModel from './model'

const ConnectPayPal = ({ onSuccess, onFailure }) => {
  const payPalModel = new PayPalModel(onSuccess, onFailure)
  const showLoader = useSelector(state => state.payment.showLoader)

  return (
    <div className='connectPayPal'>
      <a
        className={'connect__button ' + (showLoader && 'disable')}
        href='#'
        onClick={payPalModel.handleClick}
      >
        {showLoader
          ? <svg className='button__loader' width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M17 9a8 8 0 11-8-8' stroke='#fff' strokeWidth='2' strokeLinecap='round' /></svg>
          : 'Connect with PayPal'}
      </a>
    </div>
  )
}

export default ConnectPayPal
