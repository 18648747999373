import React from 'react'
import './style.sass'
import Slider from './_slider'
import MobileSlider from './_mobileSlider'
import useWindowSize from '../../../hook/useWindowSize'

const Banner = () => {
  const windowSize = useWindowSize()
  return (
    <section className='banner'>
      <div className='container'>
        <div className='subHead'>
          Designed for the modern frequent flier. Plan, book, track and manage your travels. Join our community of 6,000,000 users around the world.
        </div>
        {windowSize.desktop
          ? <Slider />
          : <MobileSlider />}

      </div>
    </section>
  )
}

export default Banner
