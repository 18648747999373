import React from 'react'
import './_vacancy.sass'

const Vacancy = ({ vacancy }) => {
  return (
    <div className='vacancy'>
      <a
        href={vacancy.link}
        target='blank'
        rel='opener'
        className='vacancy__name'
      >
        {vacancy.name}
      </a>
      <div className='vacancy__location'>{vacancy.location}</div>
    </div>
  )
}

export default Vacancy
