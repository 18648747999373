import style from './style.module.sass'

export const LandingSpot = () => {
  return (
    <section className={style.container}>
      <h2 className={style.title}>A Potential Landing Spot</h2>
      <p className={style.paragraph}>
        In this transition, we want to provide you with alternative options to plan, track, and manage your travel. <a href='https://www.tripit.com/web/appintheair' className={style.link}>TripIt</a> is a free app that helps you organize your itinerary. <a href='https://www.tripit.com/web/appintheair' className={style.link}>TripIt</a> Pro provides premium features like real-time flight alerts, fare drop alerts, interactive airport maps, and more helpful reminders to keep you in the know. They’ve provided a special offer specifically for App in the Air users. View <a href='https://www.tripit.com/web/appintheair' className={style.link}>here</a> to learn more.
      </p>
    </section>
  )
}
