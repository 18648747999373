import React from 'react'
import ConnectPayPal from '../payButtons/payPal/connect'
import PayPalModel from '../payButtons/payPal/model'

const TypePayPalConnect = () => {
  const payPalModel = new PayPalModel()
  return (
    <>
      <div className='popup__head'>
        Connect PayPal to get cashback
      </div>
      <div className='popup__text'>
        You will get your cashback to the connected PayPal account. After connection, you can use Apple Pay or PayPal to activate subcription.
      </div>
      <div className='popup__btn'>
        <ConnectPayPal
          onSuccess={payPalModel.handleSetPayPalAccount}
          onFailure={(error) => { console.log(error.message) }}
        />
      </div>
    </>
  )
}

export default TypePayPalConnect
