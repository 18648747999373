import Config, { TYPE_COMMUNITY, TYPE_SUBSCRIPTION, TYPE_PRIVACY, TYPE_FLIGHT_REVIEW } from './config'

export default class Api extends Config {
    community = async (email, type) => {
      return await this.request(TYPE_COMMUNITY, 'community', {
        email,
        type
      })
    }

    paymentIntent = async () => {
      return await this.request(TYPE_SUBSCRIPTION, 'payment/stripe/intent')
    }

    subscriptionInfo = async (userId) => {
      return await this.request(TYPE_SUBSCRIPTION, `info/${userId}`, null, 'GET')
    }

    subscriptionActivate = async (userId, payment) => {
      return await this.request(TYPE_SUBSCRIPTION, 'activate', {
        userId,
        type: payment.type,
        stripePayment: payment.stripePayment,
        paypalPayment: payment.paypalPayment,
        paypalAccount: payment.paypalAccount
      })
    }

    privacy = async () => {
      const headerPrivacy = await this.request(TYPE_PRIVACY, 'header?populate=paragraphs,cards.image', null, 'GET')
      const sectionsPrivacy = await this.request(TYPE_PRIVACY, 'sections?populate=paragraphs', null, 'GET')

      return { headerPrivacy, sectionsPrivacy }
    }

    flightReview = async (data) => {
      const { needToBeImproved, liked, seatClass, userCode, feedback, flightStatusId } = data
      const formData = new FormData()
      formData.set('to_be_improved_categories', needToBeImproved.join(',') || null)
      formData.set('liked_categories', liked.join(',') || null)
      formData.set('travel_class', seatClass)
      formData.set('user_code', userCode)
      feedback && feedback !== 'null' && feedback !== '' &&
        formData.set('feedback_text', feedback)
      // formData.set('feedback_attachments', feedbackAttachments)

      // TODO скорее всего надо будет вот так передовать файлы
      // if (feedbackAttachments) {
      //   feedbackAttachments.forEach(file => {
      //     formData.set('feedback_attachments', file);
      //   });
      // }

      await this.request(TYPE_FLIGHT_REVIEW, `review/flight/v2/${flightStatusId}`, formData, 'POST', 'multipart/form-data')
    }
}
