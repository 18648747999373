import React, { useState } from 'react'
import { FlightsReviewViewState, dataI } from '../types'
import { SeatClass } from './SeatClass'
import { FlightAdvantages } from './FlightAdvantages'
import { FlightImprovements } from './FlightImprovements'
import { FlightExperience } from './FlightExperience'
import { FinalScreen } from './FinalScreen'

export const FlightReview = ({ error, setError, setModalOpen }: any) => {
  const [data, setData] = useState<dataI>({ liked: [], needToBeImproved: [], feedback: '', seatClass: '' })
  const [currentPage, setCurrentPage] = useState<FlightsReviewViewState>(FlightsReviewViewState.SeatClass)

  const setCurrentPageHandler = (page) => {
    if (page === FlightsReviewViewState.FlightAdvantages) {
      setData({
        ...data,
        needToBeImproved: []
      })
    }
    setCurrentPage(page)
  }

  if (!currentPage) {
    return null
  }
  switch (currentPage) {
    case FlightsReviewViewState.SeatClass: {
      return <SeatClass setCurrentPage={setCurrentPageHandler} data={data} setData={setData} error={error && error.message} />
    }
    case FlightsReviewViewState.FlightAdvantages: {
      return <FlightAdvantages setModalOpen={setModalOpen} setCurrentPage={setCurrentPageHandler} data={data} setData={setData} error={error && error.message} />
    }
    case FlightsReviewViewState.FlightImprovements: {
      return <FlightImprovements setModalOpen={setModalOpen} setCurrentPage={setCurrentPageHandler} data={data} setData={setData} error={error && error.message} />
    }
    case FlightsReviewViewState.FlightExperience: {
      return <FlightExperience error={error} setError={setError} setCurrentPage={setCurrentPageHandler} data={data} setData={setData} />
    }
    case FlightsReviewViewState.FinalScreen: {
      return <FinalScreen error={error && error.message} />
    }
    default: {
      return null
    }
  }
}
