import React from 'react'
import Checkbox from '../checkbox'
import subData from '../../data/subscription.json'
import { useDispatch, useSelector } from 'react-redux'
import { setFlights, setStays } from './model'
import analytics from '../../analytics'

const Calc = () => {
  const dispatch = useDispatch()
  const flights = useSelector(state => state.calculation.flights)
  const stays = useSelector(state => state.calculation.stays)
  const setValue = (name, value) => {
    switch (name) {
      case 'flights':
        dispatch(setFlights(value))
        analytics.logEvent('web_subs_flight_change', value)
        analytics.logEvent('web_subs_price_change', subData[value][stays])
        break
      case 'stays':
        dispatch(setStays(value))
        analytics.logEvent('web_subs_hotel_change', value)
        analytics.logEvent('web_subs_price_change', subData[flights][value])

        break
    }
  }
  return (
    <div className='wrapper-right'>
      <div className='row'>
        <label className='label text'>
          Flights
          <small>~$200 round trip</small>
        </label>
        <div className='checkBoxes'>
          {Object.keys(subData).map(checkbox => {
            return (
              <Checkbox
                key={'activations' + checkbox}
                type='radio'
                className='activations'
                value={checkbox}
                name='flights'
                checked={checkbox === flights}
                setValue={setValue}
              />
            )
          })}
        </div>
      </div>
      <div className='row'>
        <label className='label text'>
          Hotel Stays
          <small>for each trip, ~$120 per night</small>
        </label>
        <div className='checkBoxes'>
          {Object.keys(subData[flights]).map(checkbox => {
            return (
              <Checkbox
                key={'night' + checkbox}
                type='radio'
                className='night'
                value={checkbox}
                name='stays'
                checked={checkbox === stays}
                setValue={setValue}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Calc
