import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import CalculationModel from '../component/calculation/model'
import PopupModel from '../component/paymentPopup/model'
import PaymentModel from '../component/payButtons/model'
import ApplePayModel from '../component/payButtons/applePay/model'
import PrivacyModel from '../component/privacy/model'

const rootReducer = combineReducers({
  calculation: CalculationModel,
  popup: PopupModel,
  payment: PaymentModel,
  applePay: ApplePayModel,
  privacy: PrivacyModel
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
