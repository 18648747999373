import React from 'react'
import Button from '../payButtons/_button'

const TypeHaveSubscription = ({ closePopup }) => {
  return (
    <>
      <div className='popup__head'>
        You already have a subscription
      </div>
      <div className='popup__text'>
        Book with App in the Air and get cashback. If there are any problems, <a href='#'>contact support.</a>
      </div>
      <div className='popup__btn'>
        <Button
          onClick={closePopup}
          text='Okey'
          icon='popup__button'
        />
      </div>
    </>
  )
}

export default TypeHaveSubscription
