import React from 'react'
import './style.sass'
import carbonNeutralTraveler from '../../assets/image/sustainability/carbonNeutralTraveler.png'
import carbonNeutralTravelerXs from '../../assets/image/sustainability/carbonNeutralTraveler-xs.png'
import useWindowSize from '../../hook/useWindowSize'

const CarbonNeutralTraveler = () => {
  const windowSize = useWindowSize()
  return (
    <div className='carbonNeutralTraveler'>
      <div className='carbonNeutralTraveler__description'>
        <div className='carbonNeutralTraveler__description-header'>
          Carbon Neutral Traveler
        </div>
        <div className='carbonNeutralTraveler__description-text'>
          Knowledge means choice. We want to give our users the tools they need to reduce their carbon footprint when
          possible, and offset as they desire as well.
          <br />
          <br />
          That’s why we developed a Carbon Emissions Calculator based on
          reliable sources so passengers can see the carbon impact of different options while searching for flights.
        </div>
      </div>
      <div className='carbonNeutralTraveler__img'>
        <img src={windowSize.desktop ? carbonNeutralTraveler : carbonNeutralTravelerXs} alt='carbonNeutralTraveler' />
      </div>
    </div>
  )
}

export default CarbonNeutralTraveler
