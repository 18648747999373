import { useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import corretctSortPrivacy from '../../../hook/corretctSortPrivacy'
import ContentOpener from '../_contentOpener'

const ItemBody = ({ text }) => {
  const textItems = useMemo(() =>
    text?.sort(corretctSortPrivacy).map((textItem) => {
      if (textItem.attributes.highlighted) {
        return (
          <ContentOpener
            key={textItem.id}
            text={textItem.attributes.text}
          />
        )
      }
      return <ReactMarkdown key={textItem.id}>{textItem.attributes.text}</ReactMarkdown>
    }), [text])

  return <div className='privacyList__item-text'>{textItems}</div>
}
export default ItemBody
