import React from 'react'
import './style.sass'
import competePhone1 from '../../assets/image/compete/compete_phone1.png'
import competePhone2 from '../../assets/image/compete/compete_phone2.png'
import competeImg1 from '../../assets/image/compete/compete_img1.png'
import competeImg2 from '../../assets/image/compete/compete_img2.png'
import competeImg3 from '../../assets/image/compete/compete_img3.png'
import competeImg4 from '../../assets/image/compete/compete_img4.png'
import useWindowSize from '../../hook/useWindowSize'

const Compete = () => {
  const windowSize = useWindowSize()
  return (
    <div className='compete'>
      <div className='container'>
        {windowSize.tablet &&
          <div className='head head-yellow'>
            6,000,000 users trust us around the world
          </div>}
        <div className='compete__phones'>
          <img src={competePhone1} alt='' className='phone phone-1' />
          <img src={competePhone2} alt='' className='phone phone-2' />
          <img src={competeImg1} alt='' className='icon icon-1' />
          <img src={competeImg2} alt='' className='icon icon-2' />
          <img src={competeImg3} alt='' className='icon icon-3' />
          <img src={competeImg4} alt='' className='icon icon-4' />
        </div>
        <div className='compete__desc'>
          <div className='head'>
            Compete with friends around the world
          </div>
          <div className='text'>
            Gather statistics for all the flights you've ever taken and compete in the global leaderboard.
            Get achievement badges for everything from countries visited and airplanes flown.
          </div>
          {windowSize.desktop &&
            <div className='head head-yellow'>
              6,000,000 users trust us around the world
            </div>}
        </div>
      </div>
    </div>
  )
}

export default Compete
