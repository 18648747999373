import React from 'react'
import './style.sass'
import brands from '../../assets/image/brands.png'

const Brands = () => {
  return (
    <div className='brands'>
      <div className='container'>
        <img src={brands} alt='' className='brands__img' />
      </div>
    </div>
  )
}

export default Brands
