import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFlightReviewQuery } from '../../hook/useFlightReviewQuery'
import Api from '../../api/index'
import { Question } from '../../component/question'
import { Answer } from '../../component/answer'
import style from './style.module.sass'
import { FlightsReviewViewState, PagesProps, dataI } from '../../types'
import useSuccessEvent from '../../hook/useSuccessEvent'

export const FlightExperience: React.FC<PagesProps> = ({ setCurrentPage, data, setData, setError, error }) => {
  const [disabledOnSubmit, setDisabledOnSubmit] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const api = new Api()
  const info = useFlightReviewQuery()
  const { flightStatusId }: any = useParams()
  let lastScrollTop = 0

  useEffect(() => {
    const onScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop
      if (currentScroll > lastScrollTop && lastScrollTop !== 0 && isFocused) {
        // Прокрутка вниз и текстовое поле в фокусе
        document.activeElement.blur() // Убираем фокус, закрываем клавиатуру
        setIsFocused(false) // Обновляем состояние фокуса
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll // Обновляем последнюю позицию скролла
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [isFocused]) // Зависимость от isFocused

  const handleFocus = () => {
    setTimeout(() => {
      setIsFocused(true) // Устанавливаем флаг фокуса
    }, 500)
  }

  const handleBlur = () => {
    setIsFocused(false) // Сбрасываем флаг фокуса
  }

  const handleChange = (text: string) => {
    setData((prev) => ({ ...prev, feedback: text }))
  }

  const onSubmit = (data: dataI) => {
    setDisabledOnSubmit(true)
    api.flightReview({
      needToBeImproved: data.needToBeImproved,
      liked: data.liked,
      seatClass: data.seatClass,
      userCode: info.userCode,
      feedbackAttachments: [],
      feedback: data.feedback.trim(),
      flightStatusId
    })
      .then(() => {
        useSuccessEvent()
        setCurrentPage(FlightsReviewViewState.FinalScreen)
      })
      .catch(e => {
        console.error('flightReview error: ', e)
        setError(e)
        setCurrentPage(FlightsReviewViewState.FinalScreen)
      })
      .finally(() => {
        setDisabledOnSubmit(false)
      })
  }

  return (
    <>
      <Question
        info={info}
        question='What would you like to share about your flight?'
        checkedItems={4}
        carrier={`https://storage.googleapis.com/iappintheair.appspot.com/Airlines/SquaredLogos/${info.carrier}.png`}
        type=''
      />
      <Answer
        disabledNext={disabledOnSubmit}
        form='review'
        title='Share your experience'
        onBackClick={() => {
          setCurrentPage(FlightsReviewViewState.FlightImprovements)
        }}
        onNextClick={() => {
          onSubmit(data)
        }}
      >
        <div className={style.checkboxes}>
          <textarea
            spellCheck='false'
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => handleChange(e.target.value)}
            value={data.feedback}
            rows={4}
            className={style.input}
            placeholder='How was your flight?'
          />
        </div>
      </Answer>
    </>
  )
}
