import Picture1 from '../assets/image/userTrips/1.png'
import Picture2 from '../assets/image/userTrips/2.png'
import Picture3 from '../assets/image/userTrips/3.png'
import Picture4 from '../assets/image/userTrips/4.png'

const userTrips = [
  {
    img: Picture1,
    picture_text: 'Daisy Ward',
    head: 'Service in Charles de Gaulle Airport, Paris',
    text: 'If you want the VAT refund, better to come early to the airport... Long queue plus slow service.'
  },
  {
    img: Picture2,
    picture_text: 'Steve Brewer',
    head: 'Sleeping spots in Helsinki Airport',
    text: 'The sleeping pods by gate 31 officially cost 5$/h, but I have gone there many times to find no one watching over them. You are then free to get into a pod and sleep. There is a space to store your bag under each pod.'
  },
  {
    img: Picture3,
    picture_text: 'Erigg Andre Osaland',
    head: 'Transport in Arlanda Airport, Stockholm',
    text: 'If you’re in Stockholm for a day trip, the Arlanda Express has a 6 hour fare that’s about 200 SEK cheaper than a standard adult return.'
  },
  {
    img: Picture4,
    picture_text: 'Jan van Doorn',
    head: 'Fast check in Tegel Airport, Berlin',
    text: 'If you already have your boarding pass and only hand luggage there is a fast check in at terminal C. From there you have access to all other terminals and gates.'
  }
]

export default userTrips
