import React from 'react'
import CheckoutForm from './checkoutForm'

const ApplePay = ({ color }) => {
  return (
    <div className='payBlock'>
      <CheckoutForm color={color} />
    </div>
  )
}

export default ApplePay
